import React, { useState, useEffect } from 'react';
import { Container, Typography, Box, Button, CardMedia, Grid, Paper, Divider } from '@mui/material';
import { useParams, Link } from 'react-router-dom';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../../firebase/firebase';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const SingleBlogPage = () => {
  const { id } = useParams();
  const [blog, setBlog] = useState(null);

  useEffect(() => {
    const fetchBlog = async () => {
      try {
        const blogRef = doc(db, 'blogs', id);
        const docSnap = await getDoc(blogRef);
        if (docSnap.exists()) {
          setBlog({ id: docSnap.id, ...docSnap.data() });
        } else {
          console.error('Blog does not exist');
        }
      } catch (error) {
        console.error('Error fetching blog:', error);
      }
    };
    fetchBlog();
  }, [id]);

  return (
    <Container>
      <ToastContainer />
      <Paper elevation={3} sx={{ padding: '20px', marginTop: '20px' }}>
      <Grid item xs={12} display="flex" justifyContent="flex-end">
              <Button component={Link} to="/allblogs" variant="contained" color="primary">
                Close
              </Button>
            </Grid>
        <Typography display="flex" justifyContent={"center"} alignItems="center" variant="h2" component="div" gutterBottom>
          Blog Details
        </Typography>
        {blog ? (
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography variant="h5" component="div" gutterBottom>
                <strong>Title:</strong> {blog.title}
              </Typography>
              <Divider sx={{ marginY: 2 }} />
            </Grid>
            {blog.author && (
              <Grid item xs={12}>
                <Typography variant="body1" component="div" gutterBottom>
                  <strong>Author:</strong> {blog.author}
                </Typography>
                <Divider sx={{ marginY: 2 }} />
              </Grid>
            )}
            <Grid item xs={12}>
              <Typography variant="body1" component="div" gutterBottom>
                <strong>Summary:</strong> {blog.summary}
              </Typography>
              <Divider sx={{ marginY: 2 }} />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1" component="div" gutterBottom>
                <strong>Category:</strong> {blog.category}
              </Typography>
              <Divider sx={{ marginY: 2 }} />
            </Grid>
            <Grid item xs={12}>
              <Box mt={2}>
                <Typography variant="body1" component="div" gutterBottom>
                  <strong>Description:</strong>
                </Typography>
                <Box sx={{ maxHeight: '300px', overflow: 'auto', padding: 2, border: '1px solid #ccc', borderRadius: '4px', backgroundColor: 'gray' }}>
                  <div dangerouslySetInnerHTML={{ __html: blog.description }} />
                </Box>
              </Box>
            </Grid>
            {blog.image && (
              <Grid item xs={12}>
              <Typography variant="h6" display={"flex"} justifyContent={"center"} alignItems={"center"} component="div" gutterBottom>
                  <strong>Image</strong>
                </Typography>
                <Box mt={2} display="flex" justifyContent="center">
                  <CardMedia
                    component="img"
                    image={blog.image}
                    alt={blog.title}
                    sx={{ maxWidth: '300px', maxHeight: '300px', width: '100%', height: 'auto' }}
                  />
                </Box>
              </Grid>
            )}
            {blog.seo && (
              <Grid item xs={12}>
              <Typography variant="h3" display={"flex"} justifyContent={"center"} alignItems={"center"} component="div" gutterBottom>

                  <strong>SEO Data</strong>
                </Typography>
                <Divider sx={{ marginY: 2 }} />
                <Typography variant="body1" component="div" gutterBottom>
                  <strong>SEO Title:</strong> {blog.seo.title}
                </Typography>
                <Typography variant="body1" component="div" gutterBottom>
                  <strong>SEO Description:</strong> {blog.seo.description}
                </Typography>
                {blog.seo.image && (
                  <Box mt={2}  justifyContent="center">
                  <Typography variant="h6" display={"flex"} justifyContent={"center"} alignItems={"center"} component="div" gutterBottom>

                      <strong>SEO Image:</strong>
                    </Typography>
                    <CardMedia
                     
                      component="img"
                      image={blog.seo.image}
                      alt="SEO"
                      sx={{ maxWidth: '300px', maxHeight: '300px', width: '100%', height: 'auto',margin:"auto" }}
                    />
                  </Box>
                )}
                {blog.seo.keywords && (
                  <Typography variant="body1" component="div" gutterBottom>
                    <strong>SEO Keywords:</strong> {blog.seo.keywords.join(', ')}
                  </Typography>
                )}
              </Grid>
            )}
         
          </Grid>
        ) : (
          <Typography variant="body1" component="div">
            Loading...
          </Typography>
        )}
      </Paper>
    </Container>
  );
};

export default SingleBlogPage;
