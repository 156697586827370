import React, { useState, useEffect } from 'react';
import { Container, TextField, Button, Box, Typography, MenuItem, Select, InputLabel, FormControl, Grid, IconButton, Paper, Dialog } from '@mui/material';
import { collection, addDoc, getDocs,Timestamp, doc, setDoc, updateDoc } from 'firebase/firestore';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { db } from '../../firebase/firebase';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import plusIcon from "../../assets/plusIcon.png";
import AddPhotoAlternateOutlinedIcon from "@mui/icons-material/AddPhotoAlternateOutlined";

import { set } from 'firebase/database';
import { useLocation } from 'react-router-dom';
const AddEvents = () => {
  const location = useLocation();
  const {mode="create",eventDataEdit} = location.state || {};
  const [categories, setCategories] = useState([]);
  const [archive, setArchive] = useState([]); 
  const [openDialog, setOpenDialog] = useState(false);
  const handleOpenDialog = () => setOpenDialog(true);
  const [archiveOrUpload, setArchiveOrUpload] = useState('upload');
  const handleCloseDialog = () => setOpenDialog(false);
  useEffect(() => {
    const fetchArchive = async () => {
      try {
        const archiveRef = collection(db, 'archive');
        const querySnapshot = await getDocs(archiveRef);
        const archiveData = querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
        setArchive(archiveData);
      } catch (error) {
        console.error('Error fetching archive:', error);
      }
    };
    fetchArchive();
    if (mode === "edit" && eventDataEdit) {
      console.log('Edit mode', eventDataEdit);
      setEvent({
        Title: eventDataEdit.Title || '',
        Date: new Date(eventDataEdit.Date.seconds * 1000).toISOString().split('T')[0], // Convert timestamp to YYYY-MM-DD format
        Location: eventDataEdit.Location || '',
        Time: eventDataEdit.Time || '',
        Category: eventDataEdit.Category || '',
        Description: eventDataEdit.Description || '',
        ImagePreview: eventDataEdit.Image || '',
        URL: eventDataEdit.URL || null,
      });
    }
  }, [mode,eventDataEdit]);
  const [event, setEvent] = useState({
    Title: '',
    Date: '',
    Location: '',
    Time: '',
    Category: '',
    Description: '',
    Image: '',
    ImagePreview: '',
    URL: '',
  });
  const [archiveImage, setArchiveImage] = useState(null);
  useEffect(() => {
    console.log('Fetching categories... useEffect add events page');
    const fetchCategories = async () => {
      try {
        const categoriesRef = collection(db, 'categories');
        const querySnapshot = await getDocs(categoriesRef);
        const categoriesData = querySnapshot.docs.map((doc) => doc.data().name);
        setCategories(categoriesData);
      } catch (error) {
        console.error('Error fetching categories:', error);
      }
    };
    fetchCategories();
  }, []);

    const handleSaveImageToAArchive = async (file) => {
    try {
    
      const imageURL = await handleImageUpload(file);
      const archiveRef = collection(db, 'archive');
      await addDoc(archiveRef, {
        Image: imageURL,
      });
     setArchive((prevArchive) => [...prevArchive, { Image: imageURL }]);
      toast.success('Image saved to archive successfully!');
    } catch (error) {
      console.error('Error saving image to archive:', error);
      toast.error('Error saving image to archive. Please try again.');
    }
  };

  const handleImageUpload = async (file) => {
    const storage = getStorage();
    const storageRef = ref(storage, `events/${file.name}`);
    await uploadBytes(storageRef, file);
    const downloadURL = await getDownloadURL(storageRef);
    return downloadURL;
  };

  const handleImageUploadToArchive = async (file) => {
    const storage = getStorage();
    const storageRef = ref(storage, `archive/${file.name}`);
    await uploadBytes(storageRef, file);
    const downloadURL = await getDownloadURL(storageRef);
    return downloadURL;

  }
  const [archiveSetImageUrl, setArchiveSetImageUrl] = useState('');
  const handleSetArchiveImage = (url) => {
    setEvent({
      ...event,
      ImagePreview: url,
      
    });
    setArchiveSetImageUrl(url);
    setOpenDialog(false);
  }

  const [isLoading,setIsLoading] = useState(false);
    
  
  const handleAddEvent = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    if(!event.Date){
      toast.error('Please select a date.');
      return;
    }
    if(!event.Location){
      toast.error('Please enter a location.');
      return;
    }
    if(!event.Time){
      toast.error('Please enter a time.');
      return;
    }
    if(!event.Category){
      toast.error('Please select a category.');
      return;
    }
    if(!event.Description){
      toast.error('Please enter a description.');
      return;

    }
    if(!event.Image && !archiveSetImageUrl && !event.ImagePreview){
      toast.error('Please upload an image.');
      return;
    }

    const eventDate = new Date(event.Date);

    if (isNaN(eventDate.getTime())) {
      toast.error('Please select a valid date format.');
      return;
    }


    const dateTimestamp = Timestamp.fromDate(eventDate);
    
    try {
      if(mode==="create"){
        const imageFile = event.Image;
        let imageURL = '';
        if(imageFile){
  
           imageURL = await handleImageUpload(imageFile);
        }else{
          imageURL = archiveSetImageUrl;
        }
        if(imageFile){
          const archiveURL = await handleImageUploadToArchive(imageFile);
          const archiveRef = collection(db, 'archive');
          await addDoc(archiveRef, {
    
            Image: archiveURL,
          });
        }
       
        const eventsRef = collection(db, 'events');
        await addDoc(eventsRef, {
          ...event,
          Date: dateTimestamp,
          Image: imageURL,
          
        });
      toast.success('Event added successfully!');
      setIsLoading(false);

      }else if(mode==="edit"){

        const imageFile = event.Image;
        let imageURL = '';
        if(imageFile){
  
           imageURL = await handleImageUpload(imageFile);
        }else{
          imageURL = archiveSetImageUrl;
        }
        if(imageFile){
          const archiveURL = await handleImageUploadToArchive(imageFile);
          const archiveRef = collection(db, 'archive');
          await addDoc(archiveRef, {
    
            Image: archiveURL,
          });
        }
        const eventRef = doc(db, 'events', eventDataEdit.id);
        await updateDoc(eventRef, {
          ...event,
          Date: dateTimestamp,
          Image: imageURL===""?event.ImagePreview:imageURL,
        
        });
        toast.success("Event Updated successfully")
        console.log('Event updated successfully!');
    setIsLoading(false);

      }
     
      setEvent({
        Date: '',
        Location: '',
        Time: '',
        Category: '',
        Description: '',
        Image: '',
        ImagePreview: '',
        URL: '',
      });
    } catch (error) {
    setIsLoading(false);

      console.error('Error adding event:', error);
      toast.error('Error adding event. Please try again.');
    }
    setIsLoading(false);

  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setEvent({
      ...event,
      Image: file,
      ImagePreview: URL.createObjectURL(file),
    });
    setOpenDialog(false);
  };

  const handleImageArchiveChange = (e) => {
    const file = e.target.files[0];
    setArchiveImage(file);
    handleSaveImageToAArchive(file);

  }


  return (
    <Box m="20px">
    <ToastContainer/>
    <Dialog
      open={openDialog}
      onClose={handleCloseDialog}
      sx={{
        height: "100%",
        width: "100%",
        overflow: "auto",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
      PaperProps={{
        style: {
          margin: 0,
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        },
      }}
    >
      <Box display="flex" justifyContent="center" alignItems="center" sx={{ p: 2 }}>
        <Button
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "#141b2d",
            color: "white",
            margin: "1rem",
            "&:hover": {
              backgroundColor: "#141b2d",
              borderColor: "#141b2d",
              color: "white",
            },
            ...(archiveOrUpload === 'upload'
              ? { backgroundColor: "#141b2d", color: "white" }
              : { backgroundColor: "transparent", borderColor: "#141b2d", color: "#4cceac" }),
          }}
          variant={archiveOrUpload === 'upload' ? 'contained' : 'outlined'}
          onClick={() => setArchiveOrUpload('upload')}
        >
          Upload
        </Button>
        <Button
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "#141b2d",
            color: "white",
            margin: "1rem",
            "&:hover": {
              backgroundColor: "#141b2d",
              borderColor: "#141b2d",
              color: "white",
            },
            ...(archiveOrUpload === 'archive'
              ? { backgroundColor: "#141b2d", color: "white" }
              : { backgroundColor: "transparent", borderColor: "#141b2d", color: "#4cceac" }),
          }}
          variant={archiveOrUpload === 'upload' ? 'outlined' : 'contained'}
          onClick={() => setArchiveOrUpload('archive')}
        >
          Archive
        </Button>
      </Box>

      {archiveOrUpload === 'upload' ? (
        <label htmlFor="file-upload">
          <Box
            p={1}
            display="flex"
            justifyContent="center"
            alignItems="center"
            sx={{
              cursor: "pointer",
              width: 500,
              height: 300,
              backgroundColor: "#141b2d",
              margin: "2rem",
              border: "1px solid #141b2d",
              "@media (max-width:800px)": {
                width: 250,
                height: 200,
              },
            }}
          >
            <CloudUploadIcon />
            <Typography ml={1} mt={1} variant="h5" gutterBottom style={{ cursor: "pointer" }}>
              Upload
            </Typography>
            <input
              id="file-upload"
              hidden
              accept="image/*"
              type="file"
              onChange={handleImageChange}
            />
          </Box>
        </label>
      ) : (
        <Box
          p={2}
          display="flex"
          justifyContent="center"
          alignItems="center"
          flexWrap="wrap"
          sx={{
            cursor: "pointer",
            width: 580,
            height: 500,
            "@media (max-width:800px)": {
              width: 300,
            },
          }}
        >
          <label htmlFor="file-archive-upload">
            <Paper sx={{ p: 2, m: 1, cursor: "pointer" }}>
              <img
                src={plusIcon}
                alt="Archive"
                style={{
                  width: 80,
                  height: 80,
                  objectFit: "contain",
                  filter: 'brightness(0) invert(1)'
                }}
              />
              <input
                id="file-archive-upload"
                hidden
                accept="image/*"
                type="file"
                onChange={handleImageArchiveChange}
              />
            </Paper>
          </label>
          {archive.map((item, index) => (
            <Paper
              key={index}
              sx={{ p: 2, m: 1, cursor: "pointer" }}
              onClick={() => { handleSetArchiveImage(item.Image) }}
            >
              <img
                src={item.Image}
                alt="Archive"
                style={{
                  width: 80,
                  height: 80,
                  objectFit: "contain",
                }}
              />
            </Paper>
          ))}
        </Box>
      )}
    </Dialog>
      <Typography variant="h3" display={"flex"} justifyContent={"center"} alignItems={"center"} component="div" gutterBottom>
      {mode==="edit"?"Edit Event":"Add Event"}    
        </Typography>
        <Box sx={{ mb: 3 }}>
        <TextField
            fullWidth
            variant="filled"
            label="Title"
            value={event.Title}
            onChange={(e) => setEvent({ ...event, Title: e.target.value })}
            sx={{ mb: 2 }}
            required
          />
          <TextField
            fullWidth
            variant="filled"
            label="Date"
            type="date"
            InputLabelProps={{ shrink: true }}
            value={event.Date}
            onChange={(e) => setEvent({ ...event, Date: e.target.value })}
            sx={{ mb: 2 }}
            required

          />
          <TextField
            fullWidth
            variant="filled"
            label="Location"
            value={event.Location}
            onChange={(e) => setEvent({ ...event, Location: e.target.value })}
            sx={{ mb: 2 }}
            required
          />
          <TextField
            fullWidth
            variant="filled"
            label="Time"
            type="time"
            InputLabelProps={{ shrink: true }}
            value={event.Time}
            onChange={(e) => setEvent({ ...event, Time: e.target.value })}
            sx={{ mb: 2 }}
            required
          />
          <FormControl required fullWidth variant="filled" sx={{ mb: 2 }}>
            <InputLabel >Category</InputLabel>
            <Select
              value={event.Category}
              onChange={(e) => setEvent({ ...event, Category: e.target.value })}
            >
              {categories.map((category, index) => (
                <MenuItem key={index} value={category}>
                  {category}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField
          required
            fullWidth
            variant="filled"
            label="Description"
            multiline
            rows={4}
            value={event.Description}
            onChange={(e) => setEvent({ ...event, Description: e.target.value })}
            sx={{ mb: 2 }}
          />
          <TextField
          required
            fullWidth
            variant="filled"
            label="Url"
            multiline
            rows={4}
            value={event.URL}
            onChange={(e) => setEvent({ ...event, URL: e.target.value })}
            sx={{ mb: 2 }}
          />
           <Grid item xs={12} display={"flex"}>
           
           <span
             className="uploadImg"
             onClick={() => handleOpenDialog("image")}
           >
           {
            event.ImagePreview ? (
                <div className="image-container">
      <img src={event.ImagePreview} alt="Blog" />
      <div className="overlay">Edit</div>
    </div>
              ) : (
                <>

                <AddPhotoAlternateOutlinedIcon sx={{ fontSize: "6rem" }} />
                <p>Upload Image</p>
                </>
              )
           }
             
           </span>
           </Grid>



          <Box mt={2} textAlign="center" >
            <Button type="submit" color="secondary"  variant="contained" onClick={(e)=>handleAddEvent(e)}>
            {
  isLoading ? (mode === "edit" ? "Editing..." : "Adding...") : (mode === "edit" ? "Edit Event" : "Add Event")
}

            </Button>
          </Box>
        </Box>
     
    </Box>
  );
};

export default AddEvents;
