import React, { useState, useEffect } from 'react';
import { Box, Typography, useTheme, Button, FormControl, InputLabel, Select, Checkbox, MenuItem, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { Navigate, useNavigate } from 'react-router-dom'; 
import { getDocs, collection, query, doc, updateDoc, deleteDoc, where } from 'firebase/firestore';
import { db } from '../../firebase/firebase.js';
import EditIcon from '@mui/icons-material/Edit';
import { tokens } from '../../theme';
import Header from '../../components/Header';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DeleteIcon from '@mui/icons-material/Delete';
import { auth } from '../../firebase/firebase.js';
import { deleteUser } from 'firebase/auth';
const Team = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [users, setUsers] = useState([]);
  const navigate = useNavigate();
  const [roles, setRoles] = useState([]);
  const [open, setOpen] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [selectedUser, setSelectedUser] = useState({
    Name: '',
    Email: '',
    "Phone Number": '',
    Status: '',
    Role: [],
  });
  const [userToDelete, setUserToDelete] = useState(null);

  useEffect(() => {
    document.title = 'Team | Admin Panel';
    const fetchRoles = async () => {
      try {
        const rolesRef = collection(db, "Roles");
        const q = query(rolesRef);
        const querySnapshot = await getDocs(q);
        const rolesData = querySnapshot.docs.map((doc) => doc.data().Name);
        setRoles(rolesData);
      } catch (error) {
        console.error("Error fetching roles:", error);
      }
    };
    fetchRoles();
  }, []);

  const handleRoleChange = (event) => {
    const { target: { value } } = event;
    setSelectedUser((prevUser) => ({
      ...prevUser,
      Role: typeof value === 'string' ? value.split(',') : value,
    }));
  };

  const handleStatusChange = (event) => {
    const { value } = event.target;
    setSelectedUser((prevUser) => ({
      ...prevUser,
      Status: value,
    }));
  };

  const handleDelete = async () => {
    try {
      console.log('Deleting user:', userToDelete);
    if (userToDelete.Role.includes("Author")) {
      const authorsCollection = collection(db, 'authors');
      const q = query(authorsCollection, where("name", "==", userToDelete.Name));
      const querySnapshot = await getDocs(q);
      
      querySnapshot.forEach(async (doc) => {
        await deleteDoc(doc.ref);
      });
    }
      
      await deleteDoc(doc(db, 'AdminPanelUsers', userToDelete.id));
      setUsers((prevUsers) => prevUsers.filter((user) => user.id !== userToDelete.id));
      toast.success('User deleted successfully!');
      setOpenDeleteDialog(false);
      setUserToDelete(null);
    } catch (error) {
      console.error('Error deleting user:', error);
      toast.error('Error deleting user!');
    }
  };

  const handleOpenDeleteDialog = (user) => {
    setUserToDelete(user);
    setOpenDeleteDialog(true);
  };

  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog(false);
    setUserToDelete(null);
  };

  useEffect(() => {
    console.log('Fetching users... team page ');
    const fetchUsers = async () => {
      const usersCollection = collection(db, 'AdminPanelUsers');
      const usersQuery = query(usersCollection);
      const snapshot = await getDocs(usersQuery);

      const usersData = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      setUsers(usersData);
    };

    fetchUsers();
  }, []);

  const handleClickOpen = (user) => {
    setSelectedUser(user);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleEdit = async () => {
    const userRef = doc(db, 'AdminPanelUsers', selectedUser.id);
    await updateDoc(userRef, selectedUser);
    setUsers((prevUsers) => prevUsers.map((user) => (user.id === selectedUser.id ? selectedUser : user)));
    setOpen(false);
    toast.success('User updated successfully!');
  };

  const renderRole = (role, index) => (
    <React.Fragment key={index}>
      <Box
        width="fit-content"
        m="0 auto"
        p="5px"
        display="flex"
        justifyContent="center"
        alignItems="center"
        backgroundColor={
          role === 'admin'
            ? colors.greenAccent[600]
            : role === 'manager'
            ? colors.greenAccent[700]
            : colors.greenAccent[700]
        }
        borderRadius="4px"
        sx={{ mr: '5px' }} 
      >
        <Typography color={colors.grey[100]} sx={{ ml: '5px' }}>
          {role}
        </Typography>
      </Box>
    </React.Fragment>
  );

  const columns = [
    { field: 'Name', headerName: 'Name', flex: 1 },
    { field: 'Email', headerName: 'Email', flex: 1 },
    { field: 'Phone Number', headerName: 'Phone Number', flex: 1 },
    { field: 'Status', headerName: 'Status', flex: 1,
      renderCell: ({ row: { Status } }) => (
        <Box
          width="fit-content"
          p="5px"
          display="flex"
          justifyContent="center"
          alignItems="center"
          backgroundColor={
            Status === 'active'
              ? colors.greenAccent[600]
              : colors.redAccent[600]
          }
          borderRadius="4px"
        >
          <Typography color={colors.grey[100]} sx={{ ml: '5px' }}>
            {Status}
          </Typography>
        </Box>
      ),
     },
    {
      field: 'Role',
      headerName: 'Role',
      flex: 1.5,
      renderCell: ({ row: { Role } }) => (
        <Box display="flex">
          {Array.isArray(Role) ? Role.map((role, index) => renderRole(role, index)) : renderRole(Role)}
        </Box>
      ),
    },
    {
      field: "Delete",
      headerName: "Delete",
      renderCell: (params) => (
        <Button
          variant="contained"
          color="primary"
          startIcon={<DeleteIcon />}
          onClick={() => handleOpenDeleteDialog(params.row)}
        >
          Delete
        </Button>
      ),
    },
    {
      field: 'edit',
      headerName: 'Edit',
      renderCell: (params) => (
        <Button
          variant="contained"
          color="primary"
          startIcon={<EditIcon />}
          onClick={() => {
            console.log('Navigating with mode:', 'edit', 'and userData:', params.row);
            navigate('/form', { state: { mode: 'edit', userData: params.row } });
          }}
        >
          Edit
        </Button>
      ),
    }
  ];

  const handleAddUser = () => {
    navigate('/form');
  };

  return (
    <Box m="20px">
      <ToastContainer />
      <Header title="TEAM" subtitle="Managing the Team Members" />
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Edit User</DialogTitle>
        <DialogContent>
          <DialogContentText>
            To edit this user, please enter the new details here.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Name"
            type="text"
            fullWidth
            value={selectedUser?.Name}
            onChange={(e) => setSelectedUser({ ...selectedUser, Name: e.target.value })}
          />
          <TextField
            margin="dense"
            id="email"
            label="Email Address"
            type="email"
            fullWidth
            value={selectedUser?.Email}
            onChange={(e) => setSelectedUser({ ...selectedUser, Email: e.target.value })}
          />
          <TextField
            margin="dense"
            id="phone"
            label="Phone Number"
            type="text"
            fullWidth
            value={selectedUser?.['Phone Number']}
            onChange={(e) => setSelectedUser({ ...selectedUser, "Phone Number": e.target.value })}
          />
          <FormControl fullWidth variant="filled" margin="dense">
            <InputLabel id="roles-label">Select Roles</InputLabel>
            <Select
              labelId="roles-label"
              id="roles"
              multiple
              value={selectedUser?.Role}
              onChange={handleRoleChange}
              renderValue={(selected) => selected.join(', ')}
            >
              {roles.map((role) => (
                <MenuItem key={role} value={role}>
                  <Checkbox checked={selectedUser?.Role.includes(role)} />
                  <Typography>{role}</Typography>
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl fullWidth variant="filled" margin="dense">
            <InputLabel id="status-label">Status</InputLabel>
            <Select
              labelId="status-label"
              id="status"
              value={selectedUser?.Status}
              onChange={handleStatusChange}
            >
              {['active', 'inactive'].map((status) => (
                <MenuItem key={status} value={status}>
                  <Typography>{status}</Typography>
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleEdit} color="secondary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openDeleteDialog}
        onClose={handleCloseDeleteDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Confirm Delete"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this user?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteDialog} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleDelete} color="secondary" autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
      <Box style={{ display: 'flex', justifyContent: 'flex-end', marginRight: '20px' }}>
        <Button  variant="contained" color="secondary" onClick={handleAddUser}>
          Add User
        </Button>
      </Box>
      <Box
        m="40px 0 0 0"
        height="75vh"
        sx={{
          '& .MuiDataGrid-root': {
            border: 'none',
          },
          '& .MuiDataGrid-cell': {
            borderBottom: 'none',
          },
          '& .MuiDataGrid-columnHeaders': {
            backgroundColor: colors.blueAccent[700],
            borderBottom: 'none',
          },
          '& .MuiDataGrid-virtualScroller': {
            backgroundColor: colors.primary[400],
          },
          '& .MuiDataGrid-footerContainer': {
            borderTop: 'none',
            backgroundColor: colors.blueAccent[700],
          },
          '& .MuiCheckbox-root': {
            color: `${colors.greenAccent[200]} !important`,
          }

        }}
      >
        <DataGrid  rows={users} columns={columns} />
      </Box>
    </Box>
  );
};

export default Team;
