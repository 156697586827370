import { useState, useEffect } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Topbar from "./scenes/global/Topbar";
import Sidebar from "./scenes/global/Sidebar";
import Dashboard from "./scenes/dashboard";
import Team from "./scenes/team";
import Invoices from "./scenes/invoices";
import Contacts from "./scenes/contacts";
import Bar from "./scenes/bar";
import Form from "./scenes/form";
import Login from "./scenes/login"
import Line from "./scenes/line";
import Pie from "./scenes/pie";
import FAQ from "./scenes/faq";
import AddRole from "./scenes/addrole";
import AddBlog from "./scenes/addblog";
import AllBlogsPage from './scenes/allblogs';
import SingleBlogPage from './scenes/singleblog';
import Admitinfo from "./scenes/admitinfo";
import AllAdmitinfo from "./scenes/alladmits";
import AdmitUserDetails from "./scenes/singleadmit";
import SingleEvent from "./scenes/singleevent";
import Addcategory from "./scenes/addcategory";
import Geography from "./scenes/geography";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { ColorModeContext, useMode } from "./theme";
import Calendar from "./scenes/calendar/calendar";
import AddEvents from "./scenes/addevents";
import AllEvents from "./scenes/allevents";
import Cate from "./scenes/category";
import Tags from "./scenes/tags";
import Author from "./scenes/author";
import EditProfile from "./scenes/editprofile";
import { onAuthStateChanged } from "firebase/auth";
import { auth, db } from "./firebase/firebase";
import { collection, getDocs, query, where } from "firebase/firestore";
import AddVisa from "./scenes/addVisa";
import ProtectedRoute from "./ProtectedRoute";
import Leads from "./scenes/leads";
import DisplayVisa from "./scenes/displayVisa";
import DisplayTestPrep from "./scenes/displayTestPrep";
import AddTestPrep from "./scenes/addTestPrep";

function App() {
  const [theme, colorMode] = useMode();
  const [isSidebar, setIsSidebar] = useState(true);
  const [loggedIn, setLoggedIn] = useState(false);
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setLoggedIn(true);
        console.log("User is logged in");
      } else {
        console.log("User is logged out");
        setLoggedIn(false);
      }
    });

    return () => unsubscribe();
  }, [auth]);
  

  if (!loggedIn) {
    return (
      <ColorModeContext.Provider value={colorMode}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Routes>
            <Route path="/" element={<Login />} />
            <Route path="/*" element={<Navigate to="/" />} />
          </Routes>
        </ThemeProvider>
      </ColorModeContext.Provider>
    );
  }

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <div className="app">
        <Sidebar isSidebar={isSidebar} />
          <main className="content">
            <Routes>
              {/* <Route path="/" element={

           (     roles.includes("Admin") || roles.includes("Lead Generation")) ? (<Team />) : (<Navigate to="/login" />)
              } /> */}
              <Route path="/" element={<ProtectedRoute roles={["Admin", "Lead Generation"]} />} >

                <Route path="" element={<Team />} />
              </Route>


              {/* <Route path="/team" element={

                roles.includes("Admin") || roles.includes("Lead Generation") ? (<Team />) : (<Navigate to="/login" />)
              } /> */}

<Route path="/team" element={<ProtectedRoute roles={["Admin", "Lead Generation"]} />} >

<Route path="" element={<Team />} />
</Route>
<Route path="/leads" element={<ProtectedRoute roles={["Admin", "Lead Generation"]} />} >

<Route path="" element={<Leads />} />
</Route>


              {/* <Route path="/contacts" element={<Contacts />} />
              <Route path="/invoices" element={<Invoices />} /> */}
              {/* <Route path="/form" element={


                roles.includes("Admin") || roles.includes("Lead Generation") ? (<Form />) : (<Navigate to="/login" />)
              } /> */}

<Route path="/form" element={<ProtectedRoute roles={["Admin", "Lead Generation"]} />} >

<Route path="" element={<Form />} />
</Route>


              {/* <Route path="/bar" element={<Bar />} />
              <Route path="/pie" element={<Pie />} />
              <Route path="/line" element={<Line />} />
              <Route path="/faq" element={<FAQ />} />
              <Route path="/calendar" element={<Calendar />} />
              <Route path="/geography" element={<Geography />} />
              <Route path="/login" element={<Login />} />
              <Route path="/addrole" element={<AddRole/>} /> */}

{/* 
              <Route path="/addblog" element={

                roles.includes("Admin") || roles.includes("Author") ? (<AddBlog />) : (<Navigate to="/login" />)
              } /> */}

<Route path="/addblog" element={<ProtectedRoute roles={["Admin", "Author"]} />} >

<Route path="" element={<AddBlog />} />
</Route>


              {/* <Route path="/allblogs" element={

                roles.includes("Admin") || roles.includes("Author") ? (<AllBlogsPage />) : (<Navigate to="/login" />)
              } /> */}

<Route path="/allblogs" element={<ProtectedRoute roles={["Admin", "Author"]} />} >

<Route path="" element={<AllBlogsPage />} />
</Route>
              

              {/* <Route path="/blog/:id" element={

                roles.includes("Admin") || roles.includes("Author") ? (<SingleBlogPage />) : (<Navigate to="/login" />)
              } /> */}

<Route path="/blog/:id" element={<ProtectedRoute roles={["Admin", "Author"]} />} >

<Route path="" element={<SingleBlogPage />} />
</Route>
             

{/*               
              <Route path="/admitinfo" element={

                roles.includes("Admin") || roles.includes("Events and Admissions") ? (<Admitinfo />) : (<Navigate to="/login" />)
              } /> */}


<Route path="/admitinfo" element={<ProtectedRoute roles={["Admin", "Events and Admissions"]} />} >

<Route path="" element={<Admitinfo />} />
</Route>

<Route path="/addVisa" element={<ProtectedRoute roles={["Admin", "Events and Admissions"]} />} >

<Route path="" element={<AddVisa />} />
</Route>
<Route path="/displayVisa" element={<ProtectedRoute roles={["Admin", "Events and Admissions"]} />} >

<Route path="" element={<DisplayVisa />} />
</Route>
            
<Route path="/displayTestPrep" element={<ProtectedRoute roles={["Admin", "Events and Admissions"]} />} >

<Route path="" element={<DisplayTestPrep />} />
</Route>
<Route path="/addTestPrep" element={<ProtectedRoute roles={["Admin", "Events and Admissions"]} />} >

<Route path="" element={<AddTestPrep />} />
</Route>
            

{/* 
              <Route path="/alladmits" element={

                roles.includes("Admin") || roles.includes("Events and Admissions") ? (<AllAdmitinfo />) : (<Navigate to="/login" />)
              } /> */}

<Route path="/alladmits" element={<ProtectedRoute roles={["Admin", "Events and Admissions"]} />} >

<Route path="" element={<AllAdmitinfo />} />
</Route>
        

              {/* <Route path="/allevents" element={

                roles.includes("Admin") || roles.includes("Events and Admissions") ? (<AllEvents />) : (<Navigate to="/login" />)
              } /> */}


<Route path="/allevents" element={<ProtectedRoute roles={["Admin", "Events and Admissions"]} />} >

<Route path="" element={<AllEvents />} />
</Route>

              {/* <Route path="/admit-user/:id" element={
                roles.includes("Admin") || roles.includes("Events and Admissions") ? (<AdmitUserDetails />) : (<Navigate to="/login" />)
              } /> */}

<Route path="/admit-user/:id" element={<ProtectedRoute roles={["Admin", "Events and Admissions"]} />} >

<Route path="" element={<AdmitUserDetails />} />
</Route>
{/* 
              <Route path="/addcategory" element={

                roles.includes("Admin") || roles.includes("Author") || roles.includes("Events and Admissions") ? (<Addcategory />) : (<Navigate to="/login" />)
              } /> */}

<Route path="/addcategory" element={<ProtectedRoute roles={["Admin","Author"]} />} >

<Route path="" element={<Addcategory />} />
</Route>


{/* 
              <Route path="/addevents" element={
                roles.includes("Admin") || roles.includes("Events and Admissions") ? (<AddEvents />) : (<Navigate to="/login" />)
              } /> */}

<Route path="/addevents" element={<ProtectedRoute roles={["Admin", "Events and Admissions"]} />} >

<Route path="" element={<AddEvents />} />
</Route>

{/* 
              <Route path="/event/:id" element={

                roles.includes("Admin") || roles.includes("Events and Admissions") ? (<SingleEvent />) : (<Navigate to="/login" />)
              } />
 */}

<Route path="/event/:id" element={<ProtectedRoute roles={["Admin", "Events and Admissions"]} />} >

<Route path="" element={<SingleEvent />} />
</Route>

              {/* <Route path="/editprofile/:id" element={
                roles.includes("Admin") || roles.includes("Events and Admissions")  ? (<EditProfile />) : (<Navigate to="/login" />)
              } /> */}

<Route path="/editprofile/:id" element={<ProtectedRoute roles={["Admin", "Events and Admissions"]} />} >

<Route path="" element={<EditProfile />} />
</Route>

              {/* <Route path="/tags" element={
                roles.includes("Admin") || roles.includes("Author")  || roles.includes("Events and Admissions")  ? (<Tags />) : (<Navigate to="/login" />)
              } /> */}

              
<Route path="/tags" element={<ProtectedRoute roles={["Admin","Author"]} />} >

<Route path="" element={<Tags />} />
</Route>


                 {/* <Route path="/categories" element={

                roles.includes("Admin") || roles.includes("Author") || roles.includes("Events and Admissions") ? (<Cate />) : (<Navigate to="/login" />)
                 }/> */}

<Route path="/categories" element={<ProtectedRoute roles={["Admin","Author"]} />} >

<Route path="" element={<Cate />} />
</Route>


              {/* <Route path="/author" element={
                roles.includes("Admin") || roles.includes("Author") || roles.includes("Events and Admissions") ? (<Author />) : (<Navigate to="/login" />)
              } /> */}

<Route path="/author" element={<ProtectedRoute roles={["Admin","Author"]} />} >

<Route path="" element={<Author />} />
</Route>
            </Routes>
          </main>
        </div>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}

export default App;
