import React, { useState, useEffect } from 'react';
import { Container, Typography, Box, Button, Paper } from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../../firebase/firebase';
import { toast } from 'react-toastify';

const SingleEvent = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [event, setEvent] = useState(null);

  useEffect(() => {
    console.log('Fetching event... useEffect single event page');
    const fetchEvent = async () => {
      try {
        const eventDoc = await getDoc(doc(db, 'events', id));
        if (eventDoc.exists()) {
          setEvent(eventDoc.data());
        } else {
          toast.error('Event not found.');
        }
      } catch (error) {
        console.error('Error fetching event:', error);
        toast.error('Error fetching event. Please try again.');
      }
    };
    fetchEvent();
  }, [id]);

  if (!event) {
    return (
      <Container>
        <Typography variant="h6" component="div" align="center" sx={{ mt: 4 }}>
          Loading...
        </Typography>
      </Container>
    );
  }

  return (
    <Container maxWidth="md" sx={{ mt: 4 }}>
      <Paper elevation={3} sx={{ p: 3 }}>
      <Typography variant="h3" display={"flex"} justifyContent={"center"} alignItems={"center"} component="div" gutterBottom>

          Event Details
        </Typography>
        <Box sx={{ mb: 2 }}>
          <Typography variant="subtitle1" gutterBottom>
            Date: {event.Date}
          </Typography>
          <Typography variant="body1" color="text.secondary" gutterBottom>
            Location: {event.Location}
          </Typography>
          <Typography variant="body1" color="text.secondary" gutterBottom>
            Time: {event.Time}
          </Typography>
          <Typography variant="body1" color="text.secondary" gutterBottom>
            Category: {event.Category}
          </Typography>
          <Typography variant="body1" color="text.secondary" gutterBottom>
            Description: {event.Description}
          </Typography>
          {event.Image && (
            <Box mt={2}>
              <img src={event.Image} alt={event.Description} style={{ width: '100%', maxWidth: '600px' }} />
            </Box>
          )}
        </Box>
        <Button
          variant="contained"
          color="primary"
          onClick={() => navigate('/allevents')}
        >
          Close
        </Button>
      </Paper>
    </Container>
  );
};

export default SingleEvent;
