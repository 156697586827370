import React, { useState } from 'react';
import { initializeApp } from "firebase/app";
import { getAnalytics, settings } from "firebase/analytics";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import {  Typography } from '@mui/material';

import { getFirestore, doc, getDoc, collection, getDocs, query, where } from "firebase/firestore";
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { useNavigate } from "react-router-dom";
// import {firebaseConfig} from "../../firebase/firebase.js"
import { set } from 'firebase/database';


const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading,setLoading]=useState(false);
  const [roles,setRoles]=useState([]);
  const firebaseConfig = {
    apiKey: "AIzaSyDD6G_Ddex3Ti9Te0O2u0NUmzOuV6gpwl8",
    authDomain: "atcollegeconnect.firebaseapp.com",
    projectId: "atcollegeconnect",
    storageBucket: "atcollegeconnect.appspot.com",
    messagingSenderId: "318895653677",
    appId: "1:318895653677:web:37200153f3066d64c289a4",
    measurementId: "G-622X6DKKG4"
  };
  
  const app = initializeApp(firebaseConfig);
  const analytics = getAnalytics(app);
  const auth = getAuth();
  const db = getFirestore(app);
  const navigate = useNavigate();
  // Function to get user role from Firestore
  
  const getUserRole = async (email) => {
    try {
      const colRef = collection(db, "AdminPanelUsers");
      const q = query(colRef, where("Email", "==", email));
      const querySnapshot = await getDocs(q);
  
      if (querySnapshot.empty) {
        console.log("No matching documents found for email:", email);
        return ;
      }
      const roles=[];
      querySnapshot.forEach((doc) => {
        console.log("User document:", doc.id, " =>", doc.data());
        console.log(doc.data().Role)
        if(doc.data().Role){
          roles.push(doc.data().Role)
        }
      });
      setRoles(roles)
      localStorage.setItem("roles",JSON.stringify(roles))
      return roles;
      console.log(roles)
      
    } catch (error) {
      console.error("Error fetching user role:", error);
    }
  };
  
  

  const handleSignIn = async () => {
    try {
      setLoading(true)
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;
      console.log("Signed in as:", user.email);
      const dbRole = await getUserRole(user.email);
      setLoading(false)
    } catch (error) {
      setLoading(false)
      alert("Invalid Credentials, try again...",error);
      console.error("Error signing in:", error);
    }
  };
    
  
  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', minHeight: '100vh' }}>
    <Typography variant="h4" gutterBottom style={{ marginBottom: '20px' }}>
      Login
    </Typography>
    <div style={{ maxWidth: '300px', width: '100%' }}>
      <TextField
        label="Email"
        variant="outlined"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        style={{ marginBottom: '20px', width: '100%' }}
      />
      <TextField
        label="Password"
        variant="outlined"
        type="password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        style={{ marginBottom: '20px', width: '100%' }}
      />
      <Button variant="contained" color="secondary" onClick={handleSignIn} style={{ width: '100%' }}>
       {loading?"Logging In...":"Login In"}
      </Button>
    </div>
  </div>
  );
};

export default Login;
