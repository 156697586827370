import React, { useState, useEffect } from 'react';
import { Container, Typography, Box, TextField, Button, Dialog, DialogActions, useTheme, DialogContent, DialogTitle, IconButton } from '@mui/material';
import { collection, addDoc, getDocs, updateDoc, deleteDoc, doc, Timestamp } from 'firebase/firestore';
import { db } from '../../firebase/firebase';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import EditIcon from '@mui/icons-material/Edit';
import { DataGrid } from '@mui/x-data-grid';
import DeleteIcon from '@mui/icons-material/Delete';
import { tokens } from '../../theme';
import Header from '../../components/Header';

const TagPage = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [tags, setTags] = useState([]);
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [selectedTag, setSelectedTag] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [addDialogOpen, setAddDialogOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [tagToDelete, setTagToDelete] = useState(null);
  const [slug, setSlug] = useState('');

  useEffect(() => {
    console.log('Fetching tags... useEffect all tags page')
    const fetchTags = async () => {
      try {
        const tagsRef = collection(db, 'tags');
        const querySnapshot = await getDocs(tagsRef);
        const tagsData = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
        }));
        setTags(tagsData);
      } catch (error) {
        console.error('Error fetching tags:', error);
      }
    };

    fetchTags();
  }, []);

  useEffect(()=>{
    document.title = 'All Tags | Admin Panel';
    console.log('slug updating .... tag page',slug);
    setSlug(name.toLowerCase().replace(/ /g, '-'));
  }, [name]);

  const handleAddTag = async () => {
    if (name.trim() === '') return;

    try {
      const tagsRef = collection(db, 'tags');
      await addDoc(tagsRef, {
        name,
        slug,
        description,
        createdAt: Timestamp.fromDate(new Date()),
      });
      toast.success('Tag added successfully!');
      setName('');
      setDescription('');
      const newTagsSnapshot = await getDocs(tagsRef);
      const newTags = newTagsSnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
      }));
      setTags(newTags);
      setAddDialogOpen(false);
    } catch (error) {
      console.error('Error adding tag:', error);
      toast.error('Failed to add tag. Please try again.');
    }
  };

  const handleUpdateTag = async () => {
    if (!selectedTag || name.trim() === '') return;

    try {
      const tagRef = doc(db, 'tags', selectedTag.id);
      await updateDoc(tagRef, {
        name,
        slug,
        description,
      });
      toast.success('Tag updated successfully!');
      setDialogOpen(false);
      const updatedTagsSnapshot = await getDocs(collection(db, 'tags'));
      const updatedTags = updatedTagsSnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
      }));
      setTags(updatedTags);
    } catch (error) {
      console.error('Error updating tag:', error);
      toast.error('Failed to update tag. Please try again.');
    }
  };

  const handleDeleteTag = async () => {
    if (!tagToDelete) return;

    try {
      const tagRef = doc(db, 'tags', tagToDelete);
      await deleteDoc(tagRef);
      toast.success('Tag deleted successfully!');
      const remainingTagsSnapshot = await getDocs(collection(db, 'tags'));
      const remainingTags = remainingTagsSnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
      }));
      setTags(remainingTags);
      setDeleteDialogOpen(false);
      setTagToDelete(null);
    } catch (error) {
      console.error('Error deleting tag:', error);
      toast.error('Failed to delete tag. Please try again.');
    }
  };

  const handleDialogOpen = (tag) => {
    setSelectedTag(tag);
    setName(tag.name);
    setDescription(tag.description);
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
    setSelectedTag(null);
    setName('');
    setDescription('');
  };

  const handleAddDialogOpen = () => {
    setAddDialogOpen(true);
  };

  const handleAddDialogClose = () => {
    setAddDialogOpen(false);
    setName('');
    setDescription('');
  };

  const handleDeleteDialogOpen = (tagId) => {
    setTagToDelete(tagId);
    setDeleteDialogOpen(true);
  };

  const handleDeleteDialogClose = () => {
    setDeleteDialogOpen(false);
    setTagToDelete(null);
  };

  const columns = [
    { field: 'name', headerName: 'Name', width: 200 },
    { field: 'slug', headerName: 'Slug', width: 200 },
    { field: 'description', headerName: 'Description', width: 400 },
    { field: 'createdAt', headerName: 'Created At', width: 200, valueGetter: (params) => params.row.createdAt.toDate().toLocaleString() },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 200,
      renderCell: (params) => (
        <>
          <IconButton onClick={() => handleDialogOpen(params.row)} color="secondary">
            <EditIcon />
          </IconButton>
          <IconButton onClick={() => handleDeleteDialogOpen(params.row.id)} color="secondary">
            <DeleteIcon />
          </IconButton>
        </>
      ),
    },
  ];

  return (
    <Box m={"20px"}>
      <ToastContainer />
      <Header title="ALL TAGS" subtitle="Managing All TAGS" />
      <Box style={{ display: 'flex', justifyContent: 'flex-end', marginRight: '20px' }}>
        <Button variant="contained" color="secondary" onClick={handleAddDialogOpen}>
          Add Tag
        </Button>
      </Box>
      <Box
        m="40px 0 0 0"
        height="75vh"
        sx={{
          '& .MuiDataGrid-root': {
            border: 'none',
          },
          '& .MuiDataGrid-cell': {
            borderBottom: 'none',
          },
          '& .MuiDataGrid-columnHeaders': {
            backgroundColor: colors.blueAccent[700],
            borderBottom: 'none',
          },
          '& .MuiDataGrid-virtualScroller': {
            backgroundColor: colors.primary[400],
          },
          '& .MuiDataGrid-footerContainer': {
            borderTop: 'none',
            backgroundColor: colors.blueAccent[700],
          },
          '& .MuiCheckbox-root': {
            color: `${colors.greenAccent[200]} !important`,
          }

        }}
      >
        <DataGrid rows={tags} columns={columns} pageSize={5} />
      </Box>
      <Dialog open={addDialogOpen} onClose={handleAddDialogClose}>
        <DialogTitle>Add Tag</DialogTitle>
        <DialogContent>
          <TextField
            fullWidth
            variant="outlined"
            label="Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            sx={{ mb: 2 }}
          />
          
          <TextField
            fullWidth
            variant="outlined"
            label="Slug"
            value={slug}
            onChange={(e) => setSlug(e.target.value)}
            sx={{ mb: 2 }}
          />

          <TextField
            fullWidth
            variant="outlined"
            label="Description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            sx={{ mb: 2 }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleAddDialogClose} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleAddTag} color="secondary">
            Add
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={dialogOpen} onClose={handleDialogClose}>
        <DialogTitle>Update Tag</DialogTitle>
        <DialogContent>
          <TextField
            fullWidth
            variant="outlined"
            label="Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            sx={{ mb: 2 }}
          />
          <TextField
            fullWidth
            variant="outlined"
            label="Slug"
            value={slug}
            onChange={(e) => setSlug(e.target.value)}
            sx={{ mb: 2 }}
          />
          <TextField
            fullWidth
            variant="outlined"
            label="Description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            sx={{ mb: 2 }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleUpdateTag} color="secondary">
            Update
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={deleteDialogOpen} onClose={handleDeleteDialogClose}>
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent>
          <Typography>Are you sure you want to delete this tag?</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteDialogClose} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleDeleteTag} color="secondary">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default TagPage;
