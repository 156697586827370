import React, { useEffect, useState } from 'react';
import { Box, TextField, Button, Typography, Container, Paper, Grid, IconButton, FormControl, InputLabel, Select, MenuItem, Dialog } from '@mui/material';
import { collection, addDoc,getDocs, doc, updateDoc, Timestamp } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL,getStorage } from 'firebase/storage';
import { db, storage } from '../../firebase/firebase';
import { ToastContainer, toast } from 'react-toastify';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import 'react-toastify/dist/ReactToastify.css';
import Header from '../../components/Header';
import plusIcon from '../../assets/plusIcon.png';
import { useLocation } from 'react-router-dom';
import { set, update } from 'firebase/database';
import AddPhotoAlternateOutlinedIcon from "@mui/icons-material/AddPhotoAlternateOutlined";

const AdminInfo = () => {
  const locationn = useLocation();
  const { mode = "create", testPrepDataEdit } = locationn.state || {};
  
  const [name, setName] = useState('');
  const [location, setLocation] = useState('');
  const [year, setYear] = useState('');
  const [program, setProgram] = useState('');
  const [college, setCollege] = useState('');
  const [image, setImage] = useState(null);
  const [imagePreview, setImagePreview] = useState('');
  const [archive, setArchive] = useState([]); 
  const [openDialog, setOpenDialog] = useState(false);
  const [loading,setLoading] = useState(false);
    const [description, setDescription] = useState('');
  const handleOpenDialog = () => setOpenDialog(true);
  const [archiveOrUpload, setArchiveOrUpload] = useState('upload');
  const [URLL, setURLL] = useState('');
  const handleCloseDialog = () => setOpenDialog(false);
  useEffect(() => {
    console.log("useEffect admin info page")
    const fetchArchive = async () => {
      try {
        console.log("fetchArchive")
        const archiveRef = collection(db, 'archive');
        const querySnapshot = await getDocs(archiveRef);
        const archiveData = querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
        setArchive(archiveData);
      } catch (error) {
        console.error('Error fetching archive:', error);
      }
    };
    fetchArchive();
    console.log("mode in testPrep info",mode)
    if (mode === 'edit' && testPrepDataEdit) {
      setName(testPrepDataEdit.Name);
      setLocation(testPrepDataEdit.Location);
      setYear(testPrepDataEdit.Year);
      setProgram(testPrepDataEdit.Program);
      setCollege(testPrepDataEdit.College);
      setImagePreview(testPrepDataEdit.Image);
      setDescription(testPrepDataEdit.Description);
      setURLL(testPrepDataEdit.URLL);
    }
  }, [mode,testPrepDataEdit]);
  const [archiveImage, setArchiveImage] = useState(null);
  const [archiveSetImageUrl, setArchiveSetImageUrl] = useState('');
  const handleSetArchiveImage = (url) => {
    setImagePreview(url);
    setArchiveSetImageUrl(url);
    setOpenDialog(false);
  }

    
  const handleSaveImageToAArchive = async (file) => {
    try {
      console.log("handleSaveImageToAArchive")
    
      const imageURL = await handleImageUpload(file);
      const archiveRef = collection(db, 'archive');
      await addDoc(archiveRef, {
        Image: imageURL,
      });
     setArchive((prevArchive) => [...prevArchive, { Image: imageURL }]);
      toast.success('Image saved to archive successfully!');
    } catch (error) {
      console.error('Error saving image to archive:', error);
      toast.error('Error saving image to archive. Please try again.');
    }
  };
  const handleImageUploadToArchive = async (file) => {
    console.log("handleImageUploadToArchive")
    const storage = getStorage();
    const storageRef = ref(storage, `archive/${file.name}`);
    await uploadBytes(storageRef, file);
    const downloadURL = await getDownloadURL(storageRef);
    return downloadURL;

  }

  const handleImageUpload = async (file) => {
    console.log("handleImageUpload")
    const storageRef = ref(storage, `images/${file.name}`);
    await uploadBytes(storageRef, file);
    const downloadURL = await getDownloadURL(storageRef);
    return downloadURL;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    console.log("handleSubmit")
    if(!name){
      toast.error('Please enter name');
      return;
    }
    if(!location){
      toast.error('Please enter location');
      return;
    }
    if(!year){
      toast.error('Please enter year');
      return;
    }
    if(!program){
      toast.error('Please enter program');
      return;
    }
    if(!college){
      toast.error('Please enter college');
      return;
    }
    if(!image && !archiveSetImageUrl && !imagePreview){
      toast.error('Please upload image');
      return;
    }
    if(!description)
    {   
        toast.error('Please enter description');
        return;
    }



    try {
      if(mode=="create"){
        let imageURL="";
        if(image){
          imageURL = await handleImageUpload(image);
        }else{
          imageURL = archiveSetImageUrl;
        
        }
        const archiveURL =image? await handleImageUploadToArchive(image):"";
        if(image){
          const archiveRef = collection(db, 'archive');
          await addDoc(archiveRef, {
    
            Image: archiveURL,
          });
        }
       
        await addDoc(collection(db, 'testPrepInfo'), {
          Name: name,
          Location: location,
          Year: year,
          Program: program,
          Image: imageURL,
          College: college,
            Description: description,
          URLL,
          createdAt: Timestamp.fromDate(new Date()),
          updatedAt: Timestamp.fromDate(new Date()),
        });
        toast.success('testPrep Info added successfully!');
        clearForm();
      }else if (mode==="edit"){
        let imageURL="";
        if(image){
          imageURL = await handleImageUpload(image);
        }else{
          imageURL = archiveSetImageUrl;
        }
        const archiveURL =image? await handleImageUploadToArchive(image):"";
        if(image){
          const archiveRef = collection(db, 'archive');
          await addDoc(archiveRef, {
    
            Image: archiveURL,
          });
        }
        const userRef = doc(db, 'testPrepInfo', testPrepDataEdit.id);
        await updateDoc(userRef, {
          Name: name,
          Location: location,
          Year: year,
          Program: program,
          Image: imageURL==""?imagePreview:imageURL,
            Description: description,
          College: college,
          URLL,
          updatedAt: Timestamp.fromDate(new Date()),
        });
        toast.success('Admin Info updated successfully!');

      }
      setLoading(false);
      
    } catch (error) {
      setLoading(false);
      console.error('Error adding admin info:', error);
      toast.error('Error adding admin info. Please try again.');
    }
    setLoading(false);
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setImage(file);
    setImagePreview(URL.createObjectURL(file));
    
    setOpenDialog(false);
  }; 
  
  const handleImageArchiveChange = (e) => {
    const file = e.target.files[0];
    setArchiveImage(file);
    handleSaveImageToAArchive(file);

  }

  const clearForm = () => {
    console.log("clearing form...")
    setName('');
    setLocation('');
    setYear('');
    setProgram('');
    setCollege('');
    setImage(null);
    setImagePreview('');
    setURLL('');
    setDescription('');
  };

  return (
    <>
      <ToastContainer />
      <Box m="20px">
      <Dialog
      open={openDialog}
      onClose={handleCloseDialog}
      sx={{
        height: "100%",
        width: "100%",
        overflow: "auto",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
      PaperProps={{
        style: {
          margin: 0,
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        },
      }}
    >
      <Box display="flex" justifyContent="center" alignItems="center" sx={{ p: 2 }}>
        <Button
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "#141b2d",
            color: "white",
            margin: "1rem",
            "&:hover": {
              backgroundColor: "#141b2d",
              borderColor: "#141b2d",
              color: "white",
            },
            ...(archiveOrUpload === 'upload'
              ? { backgroundColor: "#141b2d", color: "white" }
              : { backgroundColor: "transparent", borderColor: "#141b2d", color: "#4cceac" }),
          }}
          variant={archiveOrUpload === 'upload' ? 'contained' : 'outlined'}
          onClick={() => setArchiveOrUpload('upload')}
        >
          Upload
        </Button>
        <Button
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "#141b2d",
            color: "white",
            margin: "1rem",
            "&:hover": {
              backgroundColor: "#141b2d",
              borderColor: "#141b2d",
              color: "white",
            },
            ...(archiveOrUpload === 'archive'
              ? { backgroundColor: "#141b2d", color: "white" }
              : { backgroundColor: "transparent", borderColor: "#141b2d", color: "#4cceac" }),
          }}
          variant={archiveOrUpload === 'upload' ? 'outlined' : 'contained'}
          onClick={() => setArchiveOrUpload('archive')}
        >
          Archive
        </Button>
      </Box>

      {archiveOrUpload === 'upload' ? (
        <label htmlFor="file-upload">
          <Box
            p={1}
            display="flex"
            justifyContent="center"
            alignItems="center"
            sx={{
              cursor: "pointer",
              width: 500,
              height: 300,
              backgroundColor: "#141b2d",
              margin: "2rem",
              border: "1px solid #141b2d",
              "@media (max-width:800px)": {
                width: 250,
                height: 200,
              },
            }}
          >
            <CloudUploadIcon />
            <Typography ml={1} mt={1} variant="h5" gutterBottom style={{ cursor: "pointer" }}>
              Upload
            </Typography>
            <input
              id="file-upload"
              hidden
              accept="image/*"
              type="file"
              onChange={(e)=>handleImageChange(e)}
            />
          </Box>
        </label>
      ) : (
        <Box
          p={2}
          display="flex"
          justifyContent="center"
          alignItems="center"
          flexWrap="wrap"
          sx={{
            cursor: "pointer",
            width: 580,
            height: 500,
            "@media (max-width:800px)": {
              width: 300,
            },
          }}
        >
          <label htmlFor="file-archive-upload">
            <Paper sx={{ p: 2, m: 1, cursor: "pointer" }}>
              <img
                src={plusIcon}
                alt="Archive"
                style={{
                  width: 80,
                  height: 80,
                  objectFit: "contain",
                  filter: 'brightness(0) invert(1)'
                }}
              />
              <input
                id="file-archive-upload"
                hidden
                accept="image/*"
                type="file"
                onChange={handleImageArchiveChange}
              />
            </Paper>
          </label>
          {archive.map((item, index) => (
            <Paper
              key={index}
              sx={{ p: 2, m: 1, cursor: "pointer" }}
              onClick={() => { handleSetArchiveImage(item.Image) }}
            >
              <img
                src={item.Image}
                alt="Archive"
                style={{
                  width: 80,
                  height: 80,
                  objectFit: "contain",
                }}
              />
            </Paper>
          ))}
    </Box>
      )}
    </Dialog>
        <Header title="Add testPrep Info" subtitle="Fill in the details below" />
      
          <form onSubmit={handleSubmit}>
            <Box sx={{ mb: 3 }}>
              <TextField
                fullWidth
                label="Name"
                variant="filled"
                value={name}
                onChange={(e) => setName(e.target.value)}
                sx={{ mb: 2 }}
              />
              <TextField
                fullWidth
                label="Location"
                variant="filled"
                value={location}
                onChange={(e) => setLocation(e.target.value)}
                sx={{ mb: 2 }}
              />
              <TextField
                fullWidth
                label="Year"
                variant="filled"
                value={year}
                onChange={(e) => setYear(e.target.value)}
                sx={{ mb: 2 }}
              />
              <TextField
                fullWidth
                label="Program"
                variant="filled"
                value={program}
                onChange={(e) => setProgram(e.target.value)}
                sx={{ mb: 2 }}
              />
              <TextField
                fullWidth
                label="Description"
                variant="filled"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                sx={{ mb: 2 }}
              />
              <TextField
                fullWidth
                label="College"
                variant="filled"
                value={college}
                onChange={(e) => setCollege(e.target.value)}
                sx={{ mb: 2 }}
              />
              <TextField

                fullWidth
                label="URL"
                variant="filled"
                value={URLL}
                onChange={(e) => setURLL(e.target.value)}
                sx={{ mb: 2 }}
              />

              {/* <Grid container alignItems="center" spacing={2} sx={{ mb: 2 }}>
                <Grid item>
                  <Typography variant="subtitle1">Upload Image</Typography>
                </Grid>
                <Grid item>
                <IconButton
          style={{
            backgroundColor: "#1976d2", 
            color: "#fff", 
            '&:hover': {
              backgroundColor: "#1565c0",
            },
          }}
          aria-label="upload picture"
          component="label"
          onClick={() => handleOpenDialog()}
        >
          
          <CloudUploadIcon />
        </IconButton>
                </Grid>
              </Grid>
              {imagePreview && (
                <Box sx={{ mb: 2 }}>
                  <img src={imagePreview} alt="Preview" style={{ width: '100%', maxHeight: 300, objectFit: 'contain' }} />
                </Box>
              )} */}




              <Grid item xs={12} display={"flex"}>
           
           <span
             className="uploadImg"
             onClick={() => handleOpenDialog("image")}
           >
           {
              imagePreview ? (
                <div className="image-container">
      <img src={imagePreview} alt="testPrep" />
      <div className="overlay">Edit</div>
    </div>
              ) : (
                <>

                <AddPhotoAlternateOutlinedIcon sx={{ fontSize: "6rem" }} />
                <p>Upload Image</p>
                </>
              )
           }
             
           </span>
           </Grid>





              <Box mt={2} textAlign="center" >
            <Button type="submit" color="secondary" variant="contained">
            {
              loading? "Adding...": "Add testPrep Info"
            }
            
            </Button>
          </Box>
            </Box>
          </form>
       
      </Box>
    </>
  );
};

export default AdminInfo;
