import React, { useState, useEffect, useRef } from 'react';
import { Box, TextField, Typography, Button, Grid, FormControl, InputLabel, Select, MenuItem, Checkbox } from '@mui/material';
import Header from '../../components/Header';
import { db, auth } from '../../firebase/firebase';
import { getDocs, collection, query, addDoc, doc, updateDoc, Timestamp, serverTimestamp, where } from "firebase/firestore";
import { createUserWithEmailAndPassword, signOut } from "firebase/auth";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useLocation, useNavigate } from 'react-router-dom';

const Form = () => {
  const location = useLocation();
  const { state } = location;
  const mode = state?.mode || 'create'; 
  const userData = state?.userData || {}; 

  const [roles, setRoles] = useState([]);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [selectedRoles, setSelectedRoles] = useState([]);
  const [errors, setErrors] = useState({});
  const [password, setPassword] = useState('');
  const [selectedStatus, setSelectedStatus] = useState('active');
  const [authorSlug, setAuthorSlug] = useState('');
  const [authorDescription, setAuthorDescription] = useState('');
  const navigate = useNavigate();

  const userDataRef = useRef(userData);
 
  useEffect(() => {
    console.log('userData: form page useEffect' , userDataRef.current);
    const fetchRoles = async () => {
      try {
        const rolesRef = collection(db, "Roles");
        const q = query(rolesRef);
        const querySnapshot = await getDocs(q);
        const rolesData = querySnapshot.docs.map((doc) => doc.data().Name);
        setRoles(rolesData);
      } catch (error) {
        console.error("Error fetching roles:", error);
      }
    };

    fetchRoles();

    if (mode === 'edit') {
      setName(userDataRef.current.Name || '');
      setEmail(userDataRef.current.Email || '');
     
      setSelectedRoles(userDataRef.current.Role || []);
      setSelectedStatus(userDataRef.current.Status || 'active');


      if (userDataRef.current.Role.includes("Author")) {
        console.log("yes author exists in roles during prefilling form");
        const fetchAuthor = async () => {
          try {
            const authorRef = collection(db, "authors");
            const q = query(authorRef, where("name", "==", userDataRef.current.Name));
            const querySnapshot = await getDocs(q);
            if (!querySnapshot.empty) {
              const authorData = querySnapshot.docs[0].data();
              setAuthorSlug(authorData.slug);
              setAuthorDescription(authorData.description);
            }
          } catch (error) {
            console.error("Error fetching author data:", error);
          }
        };
        fetchAuthor();
      }



    }
    console.log('userData: form page useEffect' , userDataRef.current);
  }, [mode]);
  useEffect(() => {
    console.log('newAuthor:', name);
    if(roles.includes("Author")){

    setAuthorSlug(name.toLowerCase().replace(/ /g, "-"));
    }
  }, [name]);
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      if (mode === 'create') {
        try {
          console.log("email starting in creating:",email);
          if(selectedRoles.includes("Author")){

            const authorName=name;
            const authorsRef = collection(db, "authors");
            const docRef = await addDoc(authorsRef, {
              name: authorName,
              slug: authorSlug,
              description: authorDescription,
              createdAt: serverTimestamp(),
            });
            
          }
          await createUserWithEmailAndPassword(auth, email, password);
          const usersRef = collection(db, "AdminPanelUsers");
          await addDoc(usersRef, {
            Name: name,
            Email: email.toLowerCase(),
           
            Role: selectedRoles,
            Status: selectedStatus,
            createdAt: Timestamp.fromDate(new Date()),
            updatedAt: Timestamp.fromDate(new Date()),
          });
          toast.success('User added successfully!');
          await signOut(auth)
          clearForm();
        } catch (error) {
          console.error("Error adding user document:", error);
          if (error.code === "auth/email-already-in-use") {
            toast.error('Email is already in use. Please use a different email.');
          } else if (error.code === "auth/weak-password") {
            toast.error('Password is too weak. Please use a stronger password.');
          } else {
            toast.error('Error adding user. Please try again.');
          }
        }
      } else if (mode === 'edit') {
        try {
          const userDoc = doc(db, "AdminPanelUsers", userDataRef.current.id);
          await updateDoc(userDoc, {
            Name: name,
            Email: email,
            Role: selectedRoles,
            Status: selectedStatus,
            updatedAt: Timestamp.fromDate(new Date()),
          });
          if (selectedRoles.includes("Author")) {
            const authorRef = collection(db, "authors");
            const q = query(authorRef, where("name", "==", userDataRef.current.Name));
            const querySnapshot = await getDocs(q);
            if (!querySnapshot.empty) {
              const authorDoc = querySnapshot.docs[0].ref;
              await updateDoc(authorDoc, {
                name: name,
                slug: authorSlug,
                description: authorDescription,
                updatedAt: serverTimestamp(),
              });
            } else {
              const authorsRef = collection(db, "authors");
              await addDoc(authorsRef, {
                name: name,
                slug: authorSlug,
                description: authorDescription,
                createdAt: serverTimestamp(),
              });
            }
          }
          toast.success('User updated successfully!');
          navigate("/team");
        } catch (error) {
          console.error("Error updating user document:", error);
          toast.error('Error updating user. Please try again.');
        }
      }
    } else {
      console.log('Form has errors. Please correct them.');
    }
  };

  const validateForm = () => {
    const errors = {};
    if (!name.trim()) {
      errors.name = 'Name is required';
    }
    if (!email.trim()) {
      errors.email = 'Email is required';
    } else if (!/^\S+@\S+\.\S+$/.test(email)) {
      errors.email = 'Invalid email format';
    }
   
    if (selectedRoles.length === 0) {
      errors.roles = 'At least one role must be selected';
    }
    if (mode === 'create' && !password.trim()) {
      errors.password = 'Password is required';
    } else if (mode === 'create' && password.length < 6) {
      errors.password = 'Password must be at least 6 characters long';
    }
    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const clearForm = () => {
    setName('');
    setEmail('');
    setSelectedRoles([]);
    setPassword('');
  };

  return (
    <>
      <ToastContainer />
      <Box m="20px">
     
        <Header title={mode === 'create' ? "CREATE USER" : "EDIT USER"} subtitle={mode === 'create' ? "Create a New User Profile" : "Edit User Profile"} />
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                error={!!errors.name}
                helperText={errors.name}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                variant="filled"
                type="email"
                label="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                error={!!errors.email}
                helperText={errors.email}
                disabled={mode === 'edit'}
              />
            </Grid>
            {mode === 'create' && (
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  variant="filled"
                  type="password"
                  label="Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  error={!!errors.password}
                  helperText={errors.password}
                />
              </Grid>
            )}
         
            <Grid item xs={12}>
              <FormControl fullWidth variant="filled" error={!!errors.roles}>
                <InputLabel id="roles-label">Select Roles</InputLabel>
                <Select
                  labelId="roles-label"
                  id="roles"
                  multiple
                  value={selectedRoles}
                  onChange={(e) => setSelectedRoles(e.target.value)}
                  renderValue={(selected) => selected.join(', ')}
                >
                  {roles.map((role) => (
                    <MenuItem key={role} value={role}>
                      <Checkbox checked={selectedRoles.includes(role)} />
                      <Typography>{role}</Typography>
                    </MenuItem>
                  ))}
                </Select>
                {errors.roles && <Typography variant="body1" color="error">{errors.roles}</Typography>}
              </FormControl>

              {
                selectedRoles.includes("Author") && (
                  <Grid item xs={12} mt={3} sx={{border:"solid 1px white",padding:"25px"}}>
                  <Header ok={true} variantt="h4" subtitle={"Author Details"} />
                  <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                error={!!errors.name}
                helperText={errors.name}
              />
                <TextField
                  fullWidth
                  variant="filled"
                  type="text"
                  label="Slug"
                  value={authorSlug}
                  onChange={(e) => setAuthorSlug(e.target.value)}
                  sx={{ mt: 2 }}

                />
                <TextField
                  fullWidth
                  variant="filled"
                  type="text"
                  label="Description"
                  value={authorDescription}
                  onChange={(e) => setAuthorDescription(e.target.value)}
                  sx={{ mt: 2 }}
                />
                  
              </Grid>
                )
              }
             
              <Grid item xs={12} mt={3}>
                <FormControl fullWidth variant="filled">
                  <InputLabel id="status-label">Status</InputLabel>
                  <Select
                    labelId="status-label"
                    id="status"
                    value={selectedStatus}
                    onChange={(e) => setSelectedStatus(e.target.value)}
                  >
                    {['active', 'inactive'].map((status) => (
                      <MenuItem key={status} value={status}>
                        <Typography>{status}</Typography>
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
          <Box mt={2} textAlign="center">
            <Button type="submit" color="secondary" variant="contained">
              {mode === 'create' ? "Create New User" : "Update User"}
            </Button>
          </Box>
        </form>
      </Box>
    </>
  );
};

export default Form;
