// src/scenes/AddCategory/index.jsx
import React, { useState } from 'react';
import { Container, TextField, Button, Box, Typography } from '@mui/material';
import { collection, addDoc } from 'firebase/firestore';
import { db } from '../../firebase/firebase';
import { toast } from 'react-toastify';

const AddCategory = () => {
  const [category, setCategory] = useState('');

  const handleAddCategory = async () => {
    try {
      const categoriesRef = collection(db, 'categories');
      await addDoc(categoriesRef, { name: category });
      setCategory('');
      toast.success('Category added successfully!');
    } catch (error) {
      console.error('Error adding category:', error);
      toast.error('Error adding category. Please try again.');
    }
  };

  return (
    <Container>
      <Typography variant="h4" component="h1" gutterBottom>
        Add Category
      </Typography>
      <Box>
        <TextField
          fullWidth
          variant="filled"
          label="Category"
          value={category}
          onChange={(e) => setCategory(e.target.value)}
        />
        <Button
          variant="contained"
          color="primary"
          onClick={handleAddCategory}
          sx={{ mt: 2 }}
        >
          Add Category
        </Button>
      </Box>
    </Container>
  );
};

export default AddCategory;
