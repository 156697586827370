import React, { useState, useEffect } from 'react';
import { Container, Typography, Box, Button, Dialog, DialogActions, useTheme, DialogContent, DialogTitle, IconButton, MenuItem, Select, FormControl, InputLabel, Chip, Tooltip } from '@mui/material';
import { collection, getDocs, updateDoc, doc, Timestamp } from 'firebase/firestore';
import { db } from '../../firebase/firebase';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { DataGrid } from '@mui/x-data-grid';
import { tokens } from '../../theme';
import Header from '../../components/Header';
import EditIcon from '@mui/icons-material/Edit';

const LeadsPage = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [leads, setLeads] = useState([]);
  const [filteredLeads, setFilteredLeads] = useState([]);
  const [statusDialogOpen, setStatusDialogOpen] = useState(false);
  const [selectedLead, setSelectedLead] = useState(null);
  const [status, setStatus] = useState('');
  const [filterStatus, setFilterStatus] = useState('All');

  useEffect(() => {
    document.title = 'All Leads | Admin Panel';
    const fetchLeads = async () => {
      try {
        const leadsRef = collection(db, 'leads');
        const querySnapshot = await getDocs(leadsRef);
        const leadsData = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
        }));
        setLeads(leadsData);
        console.log(leadsData);
        setFilteredLeads(leadsData);
      } catch (error) {
        console.error('Error fetching leads:', error);
      }
    };

    fetchLeads();
  }, []);

  const handleStatusChange = async () => {
    if (!selectedLead || status.trim() === '') return;

    try {
      const leadRef = doc(db, 'leads', selectedLead.id);
      await updateDoc(leadRef, {
        status,
        updatedAt: Timestamp.fromDate(new Date()),
      });
      toast.success('Status updated successfully!');
      const updatedLeadsSnapshot = await getDocs(collection(db, 'leads'));
      const updatedLeads = updatedLeadsSnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
      }));
      setLeads(updatedLeads);
      applyFilter(filterStatus, updatedLeads);
      setStatusDialogOpen(false);
      setSelectedLead(null);
    } catch (error) {
      console.error('Error updating status:', error);
      toast.error('Failed to update status. Please try again.');
    }
  };

  const handleStatusDialogOpen = (lead) => {
    setSelectedLead(lead);
    setStatus(lead.status);
    setStatusDialogOpen(true);
  };

  const handleStatusDialogClose = () => {
    setStatusDialogOpen(false);
    setSelectedLead(null);
  };

  const handleFilterChange = (status) => {
    setFilterStatus(status);
    applyFilter(status, leads);
  };

  const applyFilter = (status, leadsData) => {
    if (status === 'All') {
      setFilteredLeads(leadsData);
    } else {
      const filtered = leadsData.filter(lead => lead.status === status);
      setFilteredLeads(filtered);
    }
  };

  const columns = [
    { field: 'name', headerName: 'Name', width: 150 },
    { field: 'mobile', headerName: 'Mobile', width: 150 },
    { field: 'email', headerName: 'Email', width: 150 },
    { field: 'ifWhatsApp', headerName: 'WhatsApp', width: 120, renderCell: (params) => params.row.ifWhatsapp===true ? 'Yes' : 'No' },
    {
      field: 'interestedIn',
      headerName: 'Interested In',
      width: 200,
      renderCell: (params) => {
        const interests = Object.keys(params.row.interestedIn).filter(key => params.row.interestedIn[key]);
        return (
          <Tooltip title={<span style={{ fontSize: '13px' }}>{interests.join(', ')}</span>}>
            <span style={{ cursor: 'pointer' }}>{interests.length} interests</span>
          </Tooltip>
        );
      },
    }
,    
    
    { field: 'createdAt', headerName: 'Created At', width: 180, valueGetter: (params) => params.row.createdAt.toDate().toLocaleString() },
    { field: 'status', headerName: 'Status', width: 150 },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 150,
      renderCell: (params) => (
        <Button
          variant="contained"
          color="secondary"
          startIcon={<EditIcon />}
          onClick={() => handleStatusDialogOpen(params.row)}
        >
          Change Status
        </Button>
      ),
    },
  ];

  return (
    <Box m={"20px"}>
      <ToastContainer />
      <Header title="ALL LEADS" subtitle="Managing All Leads" />
      <Box style={{ display: 'flex', justifyContent: 'flex-end', marginRight: '20px' }} mb={2}>
        <Button color="secondary" variant={filterStatus === 'All' ? "contained" : "outlined"} onClick={() => handleFilterChange('All')} sx={{ mr: 1 }}>
        All
        </Button> <Button color="secondary" variant={filterStatus === 'Active' ? "contained" : "outlined"} onClick={() => handleFilterChange('Active')} sx={{ mr: 1 }}>
          Active
        </Button>
        <Button color="secondary" variant={filterStatus === 'Closed' ? "contained" : "outlined"} onClick={() => handleFilterChange('Closed')}>
          Closed
        </Button>
      </Box>
    
      <Box
        m="40px 0 0 0"
        height="75vh"
        sx={{
          '& .MuiDataGrid-root': {
            border: 'none',
          },
          '& .MuiDataGrid-cell': {
            borderBottom: 'none',
          },
          '& .MuiDataGrid-columnHeaders': {
            backgroundColor: colors.blueAccent[700],
            borderBottom: 'none',
          },
          '& .MuiDataGrid-virtualScroller': {
            backgroundColor: colors.primary[400],
          },
          '& .MuiDataGrid-footerContainer': {
            borderTop: 'none',
            backgroundColor: colors.blueAccent[700],
          },
          '& .MuiCheckbox-root': {
            color: `${colors.greenAccent[200]} !important`,
          }

        }}
      >
        <DataGrid rows={filteredLeads} columns={columns} pageSize={5} />
      </Box>
      <Dialog
        open={statusDialogOpen}
        onClose={handleStatusDialogClose}
        maxWidth="xs"  // Controls the width of the dialog
        fullWidth
        PaperProps={{
          sx: {
            bgcolor: theme.palette.background.default,  // Matches the background of the admin panel
            color: theme.palette.text.secondary,
            p: 2,
            borderRadius: 2,  // Rounded corners for a modern look
          },
        }}
      >
        <DialogContent>
          <Box sx={{ mt: 2 }}>
            <FormControl fullWidth variant="outlined" sx={{ mb: 3 }}>
              <InputLabel>Status</InputLabel>
              <Select
                value={status}
                onChange={(e) => setStatus(e.target.value)}
                label="Status"
                sx={{ bgcolor: theme.palette.background.paper }}
              >
                <MenuItem value="Active">Active</MenuItem>
                <MenuItem value="Closed">Closed</MenuItem>
              </Select>
            </FormControl>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleStatusDialogClose} color="secondary" variant="outlined" sx={{ mr: 1 }}>
            Cancel
          </Button>
          <Button onClick={handleStatusChange} color="secondary" variant="contained">
            Change
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default LeadsPage;
