import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Typography, TextField, Button, MenuItem } from '@mui/material';
import { collection, doc, getDoc, updateDoc, query, getDocs } from 'firebase/firestore';
import { db } from '../../firebase/firebase';

const EditUserPage = () => {
  const { id } = useParams();
  const [userData, setUserData] = useState({});
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phoneNumber: '',
    roles: [], // Change role to roles and make it an array
    createdAt: '',
    updatedAt: '',
    // Add more fields as needed
  });
  const [roles, setRoles] = useState([]);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const userDoc = await getDoc(doc(db, 'AdminPanelUsers', id));
        if (userDoc.exists()) {
          setUserData(userDoc.data());
          setFormData({
            name: userDoc.data().Name,
            email: userDoc.data().Email,
            phoneNumber: userDoc.data()['Phone Number'],
            roles: userDoc.data().Roles || [], // Use Roles instead of Role
            createdAt: userDoc.data().createdAt.toDate().toLocaleString(),
            updatedAt: userDoc.data().updatedAt.toDate().toLocaleString(),
            // Set more fields as needed
          });
        } else {
          console.error('User not found');
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };

    const fetchRoles = async () => {
      try {
        const rolesCollection = collection(db, 'Roles'); // Change 'Roles' to your actual collection name
        const rolesSnapshot = await getDocs(rolesCollection);
        const rolesData = rolesSnapshot.docs.map(doc => doc.data().name);
        setRoles(rolesData);
      } catch (error) {
        console.error('Error fetching roles:', error);
      }
    };

    fetchUserData();
    fetchRoles();
  }, [id]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleRoleChange = (e) => {
    const { value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      roles: value,
    }));
  };

  const handleSubmit = async () => {
    try {
      const userRef = doc(db, 'AdminPanelUsers', id);
      await updateDoc(userRef, {
        Name: formData.name,
        Email: formData.email,
        'Phone Number': formData.phoneNumber,
        Roles: formData.roles, // Change Role to Roles
        // Update more fields as needed
      });
      console.log('User data updated successfully');
      // Optionally, redirect the user to a different page after successful update
    } catch (error) {
      console.error('Error updating user data:', error);
    }
  };

  return (
    <Box>
      <Typography variant="h4" gutterBottom>
        Edit User Details
      </Typography>
      <form onSubmit={handleSubmit}>
        <TextField
          name="name"
          label="Name"
          value={formData.name}
          onChange={handleChange}
          fullWidth
          margin="normal"
          variant="outlined"
        />
        <TextField
          name="email"
          label="Email"
          value={formData.email}
          onChange={handleChange}
          fullWidth
          margin="normal"
          variant="outlined"
        />
        <TextField
          name="phoneNumber"
          label="Phone Number"
          value={formData.phoneNumber}
          onChange={handleChange}
          fullWidth
          margin="normal"
          variant="outlined"
        />
        <TextField
          select
          name="roles" // Change name to roles
          label="Roles" // Change label to Roles
          value={formData.roles}
          onChange={handleRoleChange} // Change to handleRoleChange
          fullWidth
          margin="normal"
          variant="outlined"
          SelectProps={{
            multiple: true, // Allow multiple selections
            renderValue: (selected) => selected.join(', '), // Render selected roles as comma-separated string
          }}
        >
          {roles.map((role) => (
            <MenuItem key={role} value={role}>
              {role}
            </MenuItem>
          ))}
        </TextField>
        {/* Add more fields as needed */}
        <Button type="submit" variant="contained" color="primary">
          Save
        </Button>
      </form>
    </Box>
  );
};

export default EditUserPage;
