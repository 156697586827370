import React, { useState, useEffect } from 'react';
import { Box, Typography, Button, FormControl, InputLabel, Select, Checkbox, MenuItem, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField, useTheme } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { useNavigate } from 'react-router-dom'; 
import { collection, getDocs, deleteDoc, doc, updateDoc, getDoc, Timestamp } from 'firebase/firestore';
import { getStorage, ref, deleteObject } from 'firebase/storage';
import { db } from '../../firebase/firebase';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { tokens } from '../../theme';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Header from '../../components/Header';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

const ViewAllEvents = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [events, setEvents] = useState([]);
  const [open, setOpen] = useState(false);
  const [openImage, setOpenImage] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState({
    Date: '',
    Location: '',
    Time: '',
    Category: '',
    Description: '',
  });
  const [selectedEventImage, setSelectedEventImage] = useState('');
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [eventToDelete, setEventToDelete] = useState(null);

  useEffect(() => {
    document.title = 'All Events | Admin Panel';
    console.log('Fetching events... useEffect all events page');
    const fetchEvents = async () => {
      try {
        console.log('Fetching events... try block all events page');
        const eventsRef = collection(db, 'events');
        const querySnapshot = await getDocs(eventsRef);
        const eventsData = querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
        setEvents(eventsData);
      } catch (error) {
        console.error('Error fetching events:', error);
      }
    };
    fetchEvents();
  }, []);

  const handleClickOpen = (event) => {
    setSelectedEvent(event);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpenImage = (event) => {
    setSelectedEventImage(event);
    setOpenImage(true);
  };

  const handleCloseImage = () => {
    setOpenImage(false);
  };

  const handleEdit = async () => {
    console.log('Editing event:', selectedEvent);
    const eventRef = doc(db, 'events', selectedEvent.id);
    await updateDoc(eventRef, selectedEvent);
    setEvents((prevEvents) => prevEvents.map((event) => (event.id === selectedEvent.id ? selectedEvent : event)));
    setOpen(false);
    toast.success('Event updated successfully!');
  };

  const handleDelete = async () => {
    try {
      const eventDoc = await getDoc(doc(db, 'events', eventToDelete.id));
      if (eventDoc.exists()) {
        const eventData = eventDoc.data();

        await deleteDoc(doc(db, 'events', eventToDelete.id));
        setEvents(events.filter(event => event.id !== eventToDelete.id));

        if (eventData.Image) {
          const storage = getStorage();
          const imageRef = ref(storage, eventData.Image);
          await deleteObject(imageRef);
        }

        toast.success('Event deleted successfully!');
        setOpenDeleteDialog(false);
        setEventToDelete(null);
      } else {
        toast.error('Event not found.');
      }
    } catch (error) {
      console.error('Error deleting event:', error);
      toast.error('Error deleting event. Please try again.');
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setSelectedEvent((prevEvent) => ({
      ...prevEvent,
      [name]: value,
    }));
  };

  const handleOpenDeleteDialog = (event) => {
    setEventToDelete(event);
    setOpenDeleteDialog(true);
  };

  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog(false);
    setEventToDelete(null);
  };

  const columns = [
    { 
      field: 'Date', 
      headerName: 'Date', 
      flex: 1, 
      valueGetter: (params) => params.row.Date.toDate().toLocaleString()
    },
    { field: 'Title', headerName: 'Title', flex: 1 },
    { field: 'Location', headerName: 'Location', flex: 1 },
    { field: 'Time', headerName: 'Time', flex: 1 },
    {
      field: 'Image',
      headerName: 'Image',
      flex: 1,
      renderCell: (params) => (
        <Button variant="contained" color="primary" startIcon={<OpenInNewIcon />} onClick={() => handleOpenImage(params.row.Image)}>
          View Image
        </Button>
      )
    },
    { field: 'Category', headerName: 'Category', flex: 1 },
    { field: 'Description', headerName: 'Description', flex: 2 },
    {
      field: 'edit',
      headerName: 'Edit',
      renderCell: (params) => (
        <Button
          variant="contained"
          color="primary"
          startIcon={<EditIcon />}
          onClick={() => navigate("/addevents", { state: { mode:"edit", eventDataEdit: params.row } })}
        >
          Edit
        </Button>
      ),
    },
    {
      field: 'delete',
      headerName: 'Delete',
      renderCell: (params) => (
        <Button
          variant="contained"
          color="secondary"
          startIcon={<DeleteIcon />}
          onClick={() => handleOpenDeleteDialog(params.row)}
        >
          Delete
        </Button>
      ),
    },
  ];

  const navigate = useNavigate();

  const handleAddUser = () => {
    navigate('/addevents');
  };

  return (
    <Box m="20px">
      <ToastContainer />
      <Header title="ALL EVENTS" subtitle="Managing All Events" />
      <Box style={{ display: 'flex', justifyContent: 'flex-end', marginRight: '20px' }}>
        <Button variant="contained" color="secondary" onClick={handleAddUser}>
          Add Event
        </Button>
      </Box>
      <Dialog open={openImage} onClose={handleCloseImage} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Event Image</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <img src={selectedEventImage} alt="Event" style={{ width: '100%' }} />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseImage} color="secondary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Edit Event</DialogTitle>
        <DialogContent>
          <DialogContentText>
            To edit this event, please enter the new details here.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            name="Date"
            label="Date"
            type="text"
            fullWidth
            value={selectedEvent.Date}
            onChange={handleChange}
          />
          <TextField
            margin="dense"
            name="Location"
            label="Location"
            type="text"
            fullWidth
            value={selectedEvent.Location}
            onChange={handleChange}
          />
          <TextField
            margin="dense"
            name="Time"
            label="Time"
            type="text"
            fullWidth
            value={selectedEvent.Time}
            onChange={handleChange}
          />
          <TextField
            margin="dense"
            name="Category"
            label="Category"
            type="text"
            fullWidth
            value={selectedEvent.Category}
            onChange={handleChange}
          />
          <TextField
            margin="dense"
            name="Description"
            label="Description"
            type="text"
            fullWidth
            value={selectedEvent.Description}
            onChange={handleChange}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleEdit} color="secondary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openDeleteDialog}
        onClose={handleCloseDeleteDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Confirm Delete"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this event?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDelete} color="secondary" autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
      <Box
        m="40px 0 0 0"
        height="75vh"
        sx={{
          '& .MuiDataGrid-root': {
            border: 'none',
          },
          '& .MuiDataGrid-cell': {
            borderBottom: 'none',
          },
          '& .MuiDataGrid-columnHeaders': {
            backgroundColor: colors.blueAccent[700],
            borderBottom: 'none',
          },
          '& .MuiDataGrid-virtualScroller': {
            backgroundColor: colors.primary[400],
          },
          '& .MuiDataGrid-footerContainer': {
            borderTop: 'none',
            backgroundColor: colors.blueAccent[700],
          },
          '& .MuiCheckbox-root': {
            color: `${colors.greenAccent[200]} !important`,
          }
        }}
      >
        <DataGrid  rows={events} columns={columns} />
      </Box>
    </Box>
  );
};

export default ViewAllEvents;
