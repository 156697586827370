import { useEffect, useState } from "react";
import { ProSidebar, Menu, MenuItem } from "react-pro-sidebar";
import { Box, IconButton, Typography, useTheme } from "@mui/material";
import { Link, useNavigationType } from "react-router-dom";
import "react-pro-sidebar/dist/css/styles.css";
import { tokens } from "../../theme";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import PeopleOutlinedIcon from "@mui/icons-material/PeopleOutlined";
import ContactsOutlinedIcon from "@mui/icons-material/ContactsOutlined";
import ReceiptOutlinedIcon from "@mui/icons-material/ReceiptOutlined";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import BarChartOutlinedIcon from "@mui/icons-material/BarChartOutlined";
import PieChartOutlineOutlinedIcon from "@mui/icons-material/PieChartOutlineOutlined";
import TimelineOutlinedIcon from "@mui/icons-material/TimelineOutlined";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import MapOutlinedIcon from "@mui/icons-material/MapOutlined";
import gradlerLogo from "../../assets/gradlerLogo.png"
import SchoolIcon from '@mui/icons-material/School';
import EventIcon from '@mui/icons-material/Event';
import FeedIcon from '@mui/icons-material/Feed';
import { Image } from "@mui/icons-material";
import CategoryIcon from '@mui/icons-material/Category';
import EmojiPeopleIcon from '@mui/icons-material/EmojiPeople';
import { getAuth, onAuthStateChanged, signOut } from "firebase/auth";
import {auth, db} from "../../firebase/firebase";
import LogoutIcon from '@mui/icons-material/Logout';
import { collection, getDocs, query, where } from "firebase/firestore";
const Item = ({ title, to, icon, selected, setSelected }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  return (
    <MenuItem
      active={selected === title}
      style={{
        color: colors.grey[100],
      }}
      onClick={() => setSelected(title)}
      icon={icon}
    >
      <Typography>{title}</Typography>
      <Link to={to} />
    </MenuItem>
  );
};

const Sidebar = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [selected, setSelected] = useState("Dashboard");
  const [loading, setLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [userRoles, setUserRoles] = useState([]);
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        console.log("User is logged in with email in protected route:", user.email);
        const fetchedRoles = await fetchUserRole(user.email);
        if (fetchedRoles) {
            const mainRoles= fetchedRoles[0];
          setUserRoles(mainRoles);
          setIsAuthenticated(true);
        } else {
          setIsAuthenticated(false);
        }
      } else {
        console.log("User is not logged in");
        setIsAuthenticated(false);
      }
      setLoading(false);
    });

    return () => unsubscribe();
  }, [auth]);
  const fetchUserRole = async (email) => {
    const colRef = collection(db, "AdminPanelUsers");
    const q = query(colRef, where("Email", "==", email));
    const querySnapshot = await getDocs(q);

    if (querySnapshot.empty) {
      console.log("No matching documents found for email:", email);
      return null;
    }

    const roles = [];
    querySnapshot.forEach((doc) => {
      console.log("User document:", doc.id, " =>", doc.data());
      if (doc.data().Role) {
        roles.push(doc.data().Role);
      }
    });

    console.log("roles in fetchUserRole", roles);
    return roles;
  };
  const handleLogout = async () => {
    try {
      await signOut(auth);
      localStorage.removeItem("roles");
      console.log("User signed out");
    } catch (error) {
      console.error("Error signing out:", error);
    }
  };
  return (
    <Box
      sx={{
        "& .pro-sidebar-inner": {
          background: `${colors.primary[400]} !important`,
        },
        "& .pro-icon-wrapper": {
          backgroundColor: "transparent !important",
        },
        "& .pro-inner-item": {
          padding: "5px 35px 5px 20px !important",
        },
        "& .pro-inner-item:hover": {
          color: "#868dfb !important",
        },
        "& .pro-menu-item.active": {
          color: "#6870fa !important",
        },
      }}
    >
      <ProSidebar collapsed={isCollapsed}>
        <Menu iconShape="square">
          {/* LOGO AND MENU ICON */}
          <MenuItem
            onClick={() => setIsCollapsed(!isCollapsed)}
            icon={isCollapsed ? <MenuOutlinedIcon /> : undefined}
            style={{
              margin: "10px 0 20px 0",
              color: colors.grey[100],
            }}
          >
            {!isCollapsed && (
              <Box
                display="flex"
              
                justifyContent="space-between"
                alignItems="center"
                ml="15px"
              >
                <Typography variant="h3" color={colors.grey[100]}>
                  Admin Panel
                </Typography>
                <IconButton onClick={() => setIsCollapsed(!isCollapsed)}>
                  <MenuOutlinedIcon />
                </IconButton>
              </Box>
            )}
          </MenuItem>

          {!isCollapsed && (
            <Box mb="25px">
              <Box display="flex" justifyContent="center" alignItems="center">
             
                 <img src={gradlerLogo} style={{ cursor: "pointer" }}  alt="Gradler Logo" width="200px" height="100px" />
              </Box>
             
            </Box>
          )}

          <Box display={"flex"} flexDirection={"column"} paddingLeft={isCollapsed ? undefined : "10%"}>
            {/* <Item
              title="Dashboard"
              to="/"
              icon={<HomeOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />

            <Typography
              variant="h6"
              color={colors.grey[300]}
              sx={{ m: "15px 0 5px 20px" }}
            >
              Data
            </Typography> */}

            {
              ["Admin","Lead Generation"].some((role) => userRoles.includes(role)) &&   <Item
              title="Manage Team"
              to="/team"
              icon={<PeopleOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            }
            {
              ["Admin","Lead Generation"].some((role) => userRoles.includes(role)) &&   <Item
              title="Leads"
              to="/leads"
              icon={<PeopleOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            }
          
            {/* <Item
              title="Contacts Information"
              to="/contacts"
              icon={<ContactsOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Invoices Balances"
              to="/invoices"
              icon={<ReceiptOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            /> */}
           
         {
          ["Admin","Events and Admissions"].some((role) => userRoles.includes(role)) &&  <Item
              title="Admits"
              to="/alladmits"
              icon={<SchoolIcon />}
              selected={selected}
              setSelected={setSelected}
            />
         }
         {
          ["Admin","Events and Admissions"].some((role) => userRoles.includes(role)) &&  <Item
              title="Visa"
              to="/displayVisa"
              icon={<SchoolIcon />}
              selected={selected}
              setSelected={setSelected}
            />
         }
         {
          ["Admin","Events and Admissions"].some((role) => userRoles.includes(role)) &&  <Item
              title="Test Prep"
              to="/displayTestPrep"
              icon={<SchoolIcon />}
              selected={selected}
              setSelected={setSelected}
            />
         }
           
           {
          ["Admin","Events and Admissions"].some((role) => userRoles.includes(role)) &&  <Item
              title="Events"
              to="/allevents"
              icon={<EventIcon />}
              selected={selected}
              setSelected={setSelected}
            />
           }
           
           {/* {
          ["Admin","Author"].some((role) => userRoles.includes(role)) &&  <Item
            
            title="Author"
            to="/author"
            icon={<EmojiPeopleIcon />}
            selected={selected}
            setSelected={setSelected}
          />

           } */}

            {/* <Typography
              variant="h6"
              color="secondary"
              sx={{ m: "15px 0 5px 20px" }}
              style={{textAlign:'center'}}

            >
              Forms
            </Typography>
            <Item
              title="Admit Info. Form"
              to="/admitinfo"
              icon={<ReceiptOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Profile Form"
              to="/form"
              icon={<PersonOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
         
             <Item
              title="Add Event Form"
              to="/addevents"
              icon={<ReceiptOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            /> */}
             {/* <Typography
              variant="h6"
              color="secondary"
              sx={{ m: "15px 0 5px 20px" }}
              style={{textAlign:'center'}}
            >
              BLOGS
            </Typography> */}
         {
          ["Admin","Author"].some((role) => userRoles.includes(role)) &&  

          <Item
              title="Blogs"
              to="/allblogs"
              icon={<FeedIcon />}
              selected={selected}
              setSelected={setSelected}
            />

         }

         {
          ["Admin","Author"].some((role) => userRoles.includes(role)) &&  
(<>

  <Item
            
            title="Tags"
            to="/tags"
            icon={<ReceiptOutlinedIcon />}
            selected={selected}
            setSelected={setSelected}
          />
          <Item
            
            title="Categories"
            to="/categories"
            icon={<CategoryIcon />}
            selected={selected}
            setSelected={setSelected}
          />
</>

)
         }
            
           
       <Box height={"9rem"} sx={{display:"flex",alignItems:"flex-end",zIndex:999}}>

            <MenuItem
              
              onClick={handleLogout}
              icon={<LogoutIcon />}
              style={{
                color: colors.grey[100],
              }}
            >
              <Typography>Logout</Typography>
            </MenuItem>
       </Box>
        
            {/* <Item
              title="Calendar"
              to="/calendar"
              icon={<CalendarTodayOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="FAQ Page"
              to="/faq"
              icon={<HelpOutlineOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />

            <Typography
              variant="h6"
              color={colors.grey[300]}
              sx={{ m: "15px 0 5px 20px" }}
            >
              Charts
            </Typography>
            <Item
              title="Bar Chart"
              to="/bar"
              icon={<BarChartOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Pie Chart"
              to="/pie"
              icon={<PieChartOutlineOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Line Chart"
              to="/line"
              icon={<TimelineOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Geography Chart"
              to="/geography"
              icon={<MapOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            /> */}
          </Box>
        </Menu>
      </ProSidebar>
    </Box>
  );
};

export default Sidebar;
