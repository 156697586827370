import { Outlet, Navigate } from 'react-router-dom';
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { useState, useEffect } from 'react';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { auth, db } from './firebase/firebase';

const loaderStyle = {
  border: '8px solid #f3f3f3',
  borderTop: '8px solid #3498db',
  borderRadius: '50%',
  width: '60px',
  height: '60px',
  animation: 'spin 2s linear infinite'
};

const loaderContainerStyle = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100vh'
};

const keyframesStyle = `
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
`;

const ProtectedRoute = ({ roles }) => {
  const [loading, setLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [userRoles, setUserRoles] = useState([]);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        console.log("User is logged in with email in protected route:", user.email);
        const fetchedRoles = await fetchUserRole(user.email);
        if (fetchedRoles) {
          const mainRoles = fetchedRoles[0];
          setUserRoles(mainRoles);
          setIsAuthenticated(true);
        } else {
          setIsAuthenticated(false);
        }
      } else {
        console.log("User is not logged in");
        setIsAuthenticated(false);
      }
      setLoading(false);
    });

    return () => unsubscribe();
  }, [auth]);

  const fetchUserRole = async (email) => {
    const colRef = collection(db, "AdminPanelUsers");
    const q = query(colRef, where("Email", "==", email));
    const querySnapshot = await getDocs(q);

    if (querySnapshot.empty) {
      console.log("No matching documents found for email:", email);
      return null;
    }

    const roles = [];
    querySnapshot.forEach((doc) => {
      console.log("User document:", doc.id, " =>", doc.data());
      if (doc.data().Role) {
        roles.push(doc.data().Role);
      }
    });

    console.log("roles in fetchUserRole", roles);
    return roles;
  };

  if (loading) {
    return (
      <div style={loaderContainerStyle}>
        <style>
          {keyframesStyle}
        </style>
        <div style={loaderStyle}></div>
      </div>
    );
  }

  console.log("userRoles", userRoles);
  console.log("Roles defined", roles);
  const hasAccess = roles.some(role => userRoles.includes(role));
  console.log("hasAccess", hasAccess,"is authenticated", isAuthenticated);
  return isAuthenticated && hasAccess ? <Outlet /> : <Navigate to="/login" />;
};

export default ProtectedRoute;
