import React, { useState, useEffect } from 'react';
import { Box, Typography, useTheme, Button, FormControl, InputLabel, Select, Checkbox, MenuItem, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { Link, Navigate, useNavigate } from 'react-router-dom';
import { getDocs, collection, query, doc, deleteDoc, updateDoc, getDoc } from 'firebase/firestore';
import { getStorage, ref, deleteObject } from 'firebase/storage';
import { db } from '../../firebase/firebase.js';
import EditIcon from '@mui/icons-material/Edit';
import { tokens } from '../../theme';
import Header from '../../components/Header';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

const AllAdmitUsers = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [users, setUsers] = useState([]);
  const [open, setOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState({
    Name: '',
    Location: '',
    Year: '',
    Program: '',
  });
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [userToDelete, setUserToDelete] = useState(null);
  const [selectedEventImage, setSelectedEventImage] = useState('');
  const [openImage, setOpenImage] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    document.title = 'All Admit Users | Admin Panel';
    const fetchUsers = async () => {
      try {
        const usersRef = collection(db, 'admitInfo');
        const querySnapshot = await getDocs(usersRef);
        const usersData = querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
        setUsers(usersData);
      } catch (error) {
        console.error('Error fetching users:', error);
      }
    };
    fetchUsers();
  }, []);

  const handleOpenImage = (event) => {
    setSelectedEventImage(event);
    setOpenImage(true);
  };

  const handleCloseImage = () => { 
    setOpenImage(false);
  };

  const handleClickOpen = (user) => {
    setSelectedUser(user);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setSelectedUser((prevUser) => ({
      ...prevUser,
      [name]: value,
    }));
  };

  const handleEdit = async () => {
    try {
      const userRef = doc(db, 'admitInfo', selectedUser.id);
      await updateDoc(userRef, selectedUser);
      setUsers((prevUsers) => prevUsers.map((user) => (user.id === selectedUser.id ? selectedUser : user)));
      setOpen(false);
      toast.success('Admit info updated successfully!');
    } catch (error) {
      console.error('Error updating admit info:', error);
      toast.error('Error updating admit info. Please try again.');
    }
  };

  const handleDelete = async () => {
    try {
      const userDoc = await getDoc(doc(db, 'admitInfo', userToDelete.id));
      if (userDoc.exists()) {
        const userData = userDoc.data();
        await deleteDoc(doc(db, 'admitInfo', userToDelete.id));
        setUsers(users.filter(user => user.id !== userToDelete.id));

        if (userData.Image) {
          const storage = getStorage();
          const imageRef = ref(storage, userData.Image);
          await deleteObject(imageRef);
        }

        toast.success('Admit info deleted successfully!');
        setOpenDeleteDialog(false);
        setUserToDelete(null);
      } else {
        toast.error('Admit info not found.');
      }
    } catch (error) {
      console.error('Error deleting user:', error);
      toast.error('Error deleting admit info. Please try again.');
    }
  };

  const handleOpenDeleteDialog = (user) => {
    setUserToDelete(user);
    setOpenDeleteDialog(true);
  };

  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog(false);
    setUserToDelete(null);
  };

  const columns = [
    { field: 'Name', headerName: 'Name', flex: 1 },
    { field: 'Location', headerName: 'Location', flex: 1 },
    { field: 'Year', headerName: 'Year', flex: 1 },
    { field: 'Program', headerName: 'Program', flex: 1 },
    {
      field: 'Image', headerName: 'Image', flex: 1, renderCell: (params) => (
        <Button variant="contained" color="primary" startIcon={<OpenInNewIcon />} onClick={() => handleOpenImage(params.row.Image)}>
          View Image
        </Button>
      )
    },
    {
      field: 'edit',
      headerName: 'Edit',
      renderCell: (params) => (
        <Button
          variant="contained"
          color="primary"
          startIcon={<EditIcon />}
          onClick={() => navigate('/admitinfo', { state: { mode: "edit", admitDataEdit: params.row } })}
        >
          Edit
        </Button>
      ),
    },
    {
      field: 'delete',
      headerName: 'Delete',
      renderCell: (params) => (
        <Button
          variant="contained"
          color="secondary"
          onClick={() => handleOpenDeleteDialog(params.row)}
        >
          Delete
        </Button>
      ),
    }
  ];

  const handleAddUser = () => {
    navigate("/admitinfo")
  }

  return (
    <Box m="20px">
      <ToastContainer />
      <Header title="ADMIT USERS" subtitle="Managing Admit Users" />
      <Box style={{ display: 'flex', justifyContent: 'flex-end', marginRight: '20px' }}>
        <Button variant="contained" color="secondary" onClick={handleAddUser}>
          Add Admit Info
        </Button>
      </Box>
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Edit Admit User</DialogTitle>
        <DialogContent>
          <DialogContentText>
            To edit this admit user, please enter the new details here.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            name="Name"
            label="Name"
            type="text"
            fullWidth
            variant="standard"
            value={selectedUser?.Name}
            onChange={handleChange}
          />
          <TextField
            margin="dense"
            name="Location"
            label="Location"
            type="text"
            fullWidth
            variant="standard"
            value={selectedUser?.Location}
            onChange={handleChange}
          />
          <TextField
            margin="dense"
            name="Year"
            label="Year"
            type="text"
            fullWidth
            variant="standard"
            value={selectedUser?.Year}
            onChange={handleChange}
          />
          <TextField
            margin="dense"
            name="Program"
            label="Program"
            type="text"
            fullWidth
            variant="standard"
            value={selectedUser?.Program}
            onChange={handleChange}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleEdit} color="secondary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={openImage} onClose={handleCloseImage} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Event Image</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <img src={selectedEventImage} alt="Event" style={{ width: '100%' }} />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseImage} color="secondary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openDeleteDialog}
        onClose={handleCloseDeleteDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Confirm Delete"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this user?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteDialog} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleDelete} color="secondary" autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
      <Box
        m="40px 0 0 0"
        height="75vh"
        sx={{
          '& .MuiDataGrid-root': {
            border: 'none',
          },
          '& .MuiDataGrid-cell': {
            borderBottom: 'none',
          },
          '& .MuiDataGrid-columnHeaders': {
            backgroundColor: colors.blueAccent[700],
            borderBottom: 'none',
          },
          '& .MuiDataGrid-virtualScroller': {
            backgroundColor: colors.primary[400],
          },
          '& .MuiDataGrid-footerContainer': {
            borderTop: 'none',
            backgroundColor: colors.blueAccent[700],
          },
          '& .MuiCheckbox-root': {
            color: `${colors.greenAccent[200]} !important`,
          }
        }}
      >
        <DataGrid  rows={users} columns={columns} />
      </Box>
    </Box>
  );
};

export default AllAdmitUsers;
