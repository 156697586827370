import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import {getAuth} from "firebase/auth";
import { getStorage } from 'firebase/storage';
import { getFirestore, collection, getDocs, query } from "firebase/firestore";


const firebaseConfig = {
  apiKey: "AIzaSyDD6G_Ddex3Ti9Te0O2u0NUmzOuV6gpwl8",
  authDomain: "atcollegeconnect.firebaseapp.com",
  projectId: "atcollegeconnect",
  storageBucket: "atcollegeconnect.appspot.com",
  messagingSenderId: "318895653677",
  appId: "1:318895653677:web:37200153f3066d64c289a4",
  measurementId: "G-622X6DKKG4"
};


const app = initializeApp(firebaseConfig);
// export const auth=getAuth();
// const app = initializeApp(firebaseConfig);
export const auth = getAuth(app); // Pass the app instance here
// export const db = getFirestore(app);
const db=getFirestore(app);
const analytics = getAnalytics(app);
const storage = getStorage(app);

export { db,storage };