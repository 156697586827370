import React, { useState, useEffect } from 'react';
import { Container, Typography, Box, Button, Paper } from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../../firebase/firebase';

const AdmitUserDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [user, setUser] = useState(null);

  useEffect(() => {
    console.log('Fetching user... useEffect admit user details page');
    const fetchUser = async () => {
      try {
        const userDoc = await getDoc(doc(db, 'admitInfo', id));
        if (userDoc.exists()) {
          setUser(userDoc.data());
        } else {
          console.error('No such document!');
        }
      } catch (error) {
        console.error('Error fetching user:', error);
      }
    };
    fetchUser();
  }, [id]);

  return (
    <Container maxWidth="md" sx={{ mt: 4 }}>
      <Paper elevation={3} sx={{ p: 3 }}>
        {user ? (
          <>
            <Typography variant="h4" component="h1" gutterBottom>
              Admit User Details
            </Typography>
            <Box sx={{ mb: 2 }}>
              <Typography variant="h6" component="div">
                Name: {user.Name}
              </Typography>
              <Typography variant="body1" component="div">
                Location: {user.Location}
              </Typography>
              <Typography variant="body1" component="div">
                Year: {user.Year}
              </Typography>
              <Typography variant="body1" component="div">
                Program: {user.Program}
              </Typography>
              <Typography variant="body1" component="div">
                College: {user.College}
              </Typography>
              {user.Image && (
                <Box mt={2}>
                  <img src={user.Image} alt="Uploaded" style={{ maxWidth: '100%' }} />
                </Box>
              )}
            </Box>
            <Button onClick={() => navigate('/alladmits')} variant="contained" color="primary">
              Close
            </Button>
          </>
        ) : (
          <Typography variant="h6" component="div">
            Loading...
          </Typography>
        )}
      </Paper>
    </Container>
  );
};

export default AdmitUserDetails;
