/* eslint-disable react/jsx-no-duplicate-props */


import React, { useState, useEffect, useMemo } from "react";
import {
  Container,
  Typography,
  Box,
  TextField,
  Button,
  MenuItem,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Paper,
} from "@mui/material";
import Header from "../../components/Header";
import { Editor } from "@tinymce/tinymce-react";
import {
  addDoc,
  collection,
  doc,
  getDocs,
  serverTimestamp,
  updateDoc,
} from "firebase/firestore";
import AddPhotoAlternateOutlinedIcon from "@mui/icons-material/AddPhotoAlternateOutlined";
import "./style.css";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { db,auth } from "../../firebase/firebase";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import plusIcon from "../../assets/plusIcon.png";
import { set } from 'firebase/database';
import { useLocation, useNavigate } from "react-router-dom";
import addIcon from "../../assets/addIcon.png";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import {   query, where } from 'firebase/firestore';
const BlogAddPage = () => {

  const location = useLocation();
  const { state } = location;
  //mode-->edit/create
  const mode = state?.mode || 'create'; 
  const blogData = useMemo(() => state?.blogData || {}, [state?.blogData]);

  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [userRoles, setUserRoles] = useState([]);
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        console.log("User is logged in with email in protected route:", user.email);
        const fetchedRoles = await fetchUserRole(user.email);
        if (fetchedRoles) {
            const mainRoles= fetchedRoles[0];
          setUserRoles(mainRoles);
          setIsAuthenticated(true);
        } else {
          setIsAuthenticated(false);
        }
      } else {
        console.log("User is not logged in");
        setIsAuthenticated(false);
      }
      setLoading(false);
    });

    return () => unsubscribe();
  }, [auth]);


  const fetchUserRole = async (email) => {
    const colRef = collection(db, "AdminPanelUsers");
    const q = query(colRef, where("Email", "==", email));
    const querySnapshot = await getDocs(q);

    if (querySnapshot.empty) {
      console.log("No matching documents found for email:", email);
      return null;
    }

    const roles = [];
    querySnapshot.forEach((doc) => {
      console.log("User document:", doc.id, " =>", doc.data());
      if (doc.data().Role) {
        roles.push(doc.data().Role);
      }
    });

    console.log("roles in fetchUserRole", roles);
    return roles;
  };

  const [loading,setLoading]=useState(false);
  const [title, setTitle] = useState("");
  const [slug, setSlug] = useState("");
  const [description, setDescription] = useState("");
  const [summary, setSummary] = useState("");
  const [category, setCategory] = useState("");
  const [categories, setCategories] = useState([]);
  const [newCategory, setNewCategory] = useState("");
  const [categorySlug, setCategorySlug] = useState("");
  const [categoryDescription, setCategoryDescription] = useState("");
  const [image, setImage] = useState(null);
  const [seoTitle, setSeoTitle] = useState("");
  const [seoDescription, setSeoDescription] = useState("");
  const [keywordInput, setKeywordInput] = useState("");
  const [seoKeywords, setSeoKeywords] = useState([]);
  const [tags, setTags] = useState([]);
  const [tagInput, setTagInput] = useState("");
  const [selectedTags, setSelectedTags] = useState([]);
  const [tagDialogOpen, setTagDialogOpen] = useState(false);
  const [newTag, setNewTag] = useState("");
  const [tagDescription, setTagDescription] = useState("");
  const [categoryDialogOpen, setCategoryDialogOpen] = useState(false);
  const [authors, setAuthors] = useState([]);
  const [author, setAuthor] = useState("");
  const [newAuthor, setNewAuthor] = useState("");
  const [authorSlug, setAuthorSlug] = useState("");
  const [tagSlug, setTagSlug] = useState("");
  const [authorDescription, setAuthorDescription] = useState("");
  const [authorDialogOpen, setAuthorDialogOpen] = useState(false);
  const [seoImage, setSeoImage] = useState(null);
  const [archive, setArchive] = useState([]); 
  const [openDialog, setOpenDialog] = useState(false);
  const [type,setType]=useState('');
  const [imagePreview, setImagePreview] = useState('');
  const [seoImagePreview, setSeoImagePreview] = useState('');
  const [mainDescription, setMainDescription] = useState('');
  const [isFeatured, setIsFeatured] = useState("");
  const [imageTitle, setImageTitle] = useState("");
  const [altText, setAltText] = useState("");

  const [archiveSetImageUrl, setArchiveSetImageUrl] = useState('');
  const [archiveSetSeoImageUrl, setArchiveSetSeoImageUrl] = useState('');

  const handleSetArchiveImage = (url) => {
    if(type==='image'){

      setImagePreview(url);
      setArchiveSetImageUrl(url);
    }else if(type=="seoImage"){
      setSeoImagePreview(url);
      setArchiveSetSeoImageUrl(url);
    }
    setOpenDialog(false);
  }

  const navigate = useNavigate();
  const handleOpenDialog = (type) => {
    setOpenDialog(true)

    setType(type);
  };
  const [archiveOrUpload, setArchiveOrUpload] = useState('upload');
  const handleCloseDialog = () => setOpenDialog(false);
  useEffect(()=>{
    console.log('blogData edit ....:', blogData);

    if (mode === 'edit') {
      setTitle(blogData.title || '');
      setSlug(blogData.slug || '');
      setDescription(blogData.description || '');
      setSummary(blogData.summary || '');
      setCategory(blogData.category || '');
      setImagePreview(blogData.image || null);
      setSeoImagePreview(blogData.seo?.image || null);
      setSeoTitle(blogData.seo?.title || '');
      setSeoDescription(blogData.seo?.description || '');
      setSeoKeywords(blogData.seo?.keywords || []);
      setSelectedTags(blogData.tags || []);
      setAuthor(blogData.seo?.seoauthor || '');
      setSeoKeywords(blogData.seo?.keywords || []);
      setMainDescription(blogData.mainDescription || '');
      setIsFeatured(blogData.isFeatured);
      setImageTitle(blogData.imageTitle || '');
      setAltText(blogData.altText || '');
      setAuthor(blogData.seo?.seoauthor || '');
  
    }
  },[mode,blogData])

  useEffect(() => {
   
    console.log('fetching data...');
    const fetchCategories = async () => {
      try {
        console.log('fetching categories...');
        const categoriesRef = collection(db, "categories");
        const querySnapshot = await getDocs(categoriesRef);
        const categoriesData = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setCategories(categoriesData);
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };

    const fetchAuthors = async () => {
      try {
        console.log('fetching authors...');
        const authorsRef = collection(db, "authors");
        const querySnapshot = await getDocs(authorsRef);
        const authorsData = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setAuthors(authorsData);
      } catch (error) {
        console.error("Error fetching authors:", error);
      }
    };

    const fetchTags = async () => {
      try {
        console.log('fetching tags...');
        const tagsRef = collection(db, "tags");
        const querySnapshot = await getDocs(tagsRef);
        const tagsData = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setTags(tagsData);
      } catch (error) {
        console.error("Error fetching tags:", error);
      }
    };
    const fetchArchive = async () => {
      try {
        console.log('fetching archive...');
        const archiveRef = collection(db, 'archive');
        const querySnapshot = await getDocs(archiveRef);
        const archiveData = querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
        setArchive(archiveData);
        console.log('archiveData:', archiveData);
      } catch (error) {
        console.error('Error fetching archive:', error);
      }
    };
    fetchArchive();
    fetchCategories();
    fetchAuthors();
    fetchTags();
  }, []);

  useEffect(() => {
    console.log('title:', title);
    setSlug(title.toLowerCase().replace(/ /g, "-"));
  }, [title]);

  useEffect(() => {
    console.log('newCategory:', newCategory);
    setCategorySlug(newCategory.toLowerCase().replace(/ /g, "-"));
  }, [newCategory]);

  useEffect(() => {
    console.log('newAuthor:', newAuthor);
    setAuthorSlug(newAuthor.toLowerCase().replace(/ /g, "-"));
  }, [newAuthor]);

  useEffect(() => {
    console.log('newTag:', newTag);
    setTagSlug(newTag.toLowerCase().replace(/ /g, "-"));
  }, [newTag]);

  const handleEditorChange = (content) => {
    console.log('Content editor:', content);
    setDescription(content);
  };
  const [archiveImage, setArchiveImage] = useState(null);
  const handleImageUpload = async (file) => {
    console.log('Uploading image...');
    const storage = getStorage();
    const storageRef = ref(storage, `blogs/${file.name}`);
    await uploadBytes(storageRef, file);
    const downloadURL = await getDownloadURL(storageRef);
    return downloadURL;
  };
  const handleSaveImageToAArchive = async (file) => {
    console.log('Saving image to archive...');
    try {
    
      const imageURL = await handleImageUpload(file);
      const archiveRef = collection(db, 'archive');
      await addDoc(archiveRef, {
        Image: imageURL,
      });
     setArchive((prevArchive) => [...prevArchive, { Image: imageURL }]);
      toast.success('Image saved to archive successfully!');
    } catch (error) {
      console.error('Error saving image to archive:', error);
      toast.error('Error saving image to archive. Please try again.');
    }
  };
  const handleImageUploadToArchive = async (file) => {
    console.log('Uploading image to archive...');
    const storage = getStorage();
    const storageRef = ref(storage, `archive/${file.name}`);
    await uploadBytes(storageRef, file);
    const downloadURL = await getDownloadURL(storageRef);
    return downloadURL;

  }
  const handleKeywordInputKeyPress = (e) => {
    console.log('e:', e);
    if (e.key === "Enter" && keywordInput.trim() !== "") {
      e.preventDefault();
      setSeoKeywords((prevKeywords) => [...prevKeywords, keywordInput.trim()]);
      setKeywordInput("");
    }
  };

  const handleTagInputKeyPress = (e) => {
    if (e.key === "Enter" && tagInput.trim() !== "") {
      e.preventDefault();
      setTags((prevTags) => [...prevTags, tagInput.trim()]);
      setTagInput("");
    }
  };

  const handleDeleteKeyword = (keyword) => {
    setSeoKeywords((prevKeywords) => prevKeywords.filter((k) => k !== keyword));
  };

  const handleDeleteTag = (tag) => {
    setTags((prevTags) => prevTags.filter((t) => t !== tag));
  };

  const handleAddCategory = async () => {
    console.log('Adding category...');
    if (newCategory.trim() === "") return;

    try {
      const categoriesRef = collection(db, "categories");
      const docRef = await addDoc(categoriesRef, {
        name: newCategory,
        slug: categorySlug,
        description: categoryDescription,
        createdAt: serverTimestamp(),
      });
      setCategories((prevCategories) => [
        ...prevCategories,
        {
          id: docRef.id,
          name: newCategory,
          slug: categorySlug,
          description: categoryDescription,
        },
      ]);
      setNewCategory("");
      setCategorySlug("");
      setCategoryDescription("");
      setCategoryDialogOpen(false);
    } catch (error) {
      console.error("Error adding category:", error);
      toast.error("Failed to add category. Please try again.");
    }
  };

  const handleAddAuthor = async () => {
    console.log('Adding author...');
    if (newAuthor.trim() === "") return;

    try {
      const authorsRef = collection(db, "authors");
      const docRef = await addDoc(authorsRef, {
        name: newAuthor,
        slug: authorSlug,
        description: authorDescription,
        createdAt: serverTimestamp(),
      });
      setAuthors((prevAuthors) => [
        ...prevAuthors,
        {
          id: docRef.id,
          name: newAuthor,
          slug: authorSlug,
          description: authorDescription,
        },
      ]);
      setNewAuthor("");
      setAuthorSlug("");
      setAuthorDescription("");
      setAuthorDialogOpen(false);
    } catch (error) {
      console.error("Error adding author:", error);
      toast.error("Failed to add author. Please try again.");
    }
  };

  const handleAddTag = async () => {
    console.log('Adding tag...');
    if (newTag.trim() === "") return;

    try {
      const tagsRef = collection(db, "tags");
      const docRef = await addDoc(tagsRef, {
        name: newTag,
        slug: tagSlug,
        description: tagDescription,
        createdAt: serverTimestamp(),
      });
      setTags((prevTags) => [
        ...prevTags,
        {
          id: docRef.id,
          name: newTag,
          slug: tagSlug,
          description: tagDescription,
        },
      ]);
      setNewTag("");
      setTagSlug("");
      setTagDescription("");
      setTagDialogOpen(false);
    } catch (error) {
      console.error("Error adding tag:", error);
      toast.error("Failed to add tag. Please try again.");
    }
  };

  const handleSubmit = async (e) => {
    console.log('Submitting form...');
    e.preventDefault();
    try {
      setLoading(true);
      if(mode=="create"){
        let imageURL="";
        if(image){
          imageURL = await handleImageUpload(image);
        }else{
          imageURL = archiveSetImageUrl;
        }
        let seoImageURL="";
        if(seoImage){
  
          seoImageURL = await handleImageUpload(seoImage);
        }else{
          seoImageURL = archiveSetSeoImageUrl;
  
        }
        if(image){
          const archiveURL = await handleImageUploadToArchive(image);
          const archiveRef = collection(db, 'archive');
          await addDoc(archiveRef, {
           
            Image: archiveURL,
          });
        }
        if(seoImage){
          const archiveURL = await handleImageUploadToArchive(seoImage);
          const archiveRef = collection(db, 'archive');
          await addDoc(archiveRef, {
           
            Image: archiveURL,
          });
        }
        
        const blogsRef = collection(db, "blogs");
        await addDoc(blogsRef, {
          title,
          slug,
          description,
          mainDescription,
          summary,
          category,
          isFeatured,
            imageTitle,
            altText,
          image: imageURL,
          tags: selectedTags,
          seo: {
            title: seoTitle,
            description: seoDescription,
            keywords: seoKeywords,
            image: seoImageURL,
            seoauthor: author,
          },
          createdAt: serverTimestamp(),

        });
        setLoading(false);
        toast.success("Blog added successfully!");
      }else if(mode==="edit"){
        let imageURL="";
        if(image){
          imageURL = await handleImageUpload(image);
        }else{
          imageURL = archiveSetImageUrl===""?imagePreview:archiveSetImageUrl;
        }
        let seoImageURL="";
        if(seoImage){
  
          seoImageURL = await handleImageUpload(seoImage);
        }else{
          seoImageURL = archiveSetSeoImageUrl===""?seoImagePreview:archiveSetSeoImageUrl;
  
        }
        if(image){
          const archiveURL = await handleImageUploadToArchive(image);
          const archiveRef = collection(db, 'archive');
          await addDoc(archiveRef, {
           
            Image: archiveURL,
          });
        }
        if(seoImage){
          const archiveURL = await handleImageUploadToArchive(seoImage);
          const archiveRef = collection(db, 'archive');
          await addDoc(archiveRef, {
           
            Image: archiveURL,
          });
        }
        
        const blogRef = doc(db, 'blogs', blogData.id);
        await updateDoc(blogRef, {
          title,
          slug,
          description,
          summary,
          category,
          image: imageURL,
          isFeatured,
          imageTitle,
          altText,
          tags: selectedTags,
          mainDescription,
          seo: {
            title: seoTitle,
            description: seoDescription,
            keywords: seoKeywords,
            image: seoImageURL,
            seoauthor: author,
          },
        });
        setLoading(false);
        toast.success("Blog updated successfully!");

        
      

        }
      setTitle("");
      setSlug("");
      setDescription("");
      setSummary("");
      setCategory("");
      setImage(null);
      setSeoTitle("");
      setSeoDescription("");
      setKeywordInput("");
      setIsFeatured(false);
      setImageTitle("");
      setAltText("");
      setSeoKeywords([]);
      setSelectedTags([]);
      setTagInput("");
      setMainDescription("");
      setAuthor("");
      setSeoImage(null);
      setArchiveImage(null);
      setArchiveSetImageUrl('');
      setArchiveSetSeoImageUrl('');
      setArchiveOrUpload('upload');
      setImagePreview('');
      setSeoImagePreview('');
      setAuthorDialogOpen(false);
      setCategoryDialogOpen(false);
      setTagDialogOpen(false);
      setTags([]);
      setCategories([]);
      setAuthors([]);
      setDescription("");
      setOpenDialog(false);
      navigate("/blogs");

    } catch (error) {
      setLoading(false);
      console.error("Error adding blog:", error);
      toast.error("Failed to add blog. Please try again.");
    }
  };
  const handleImageArchiveChange = (e) => {
    const file = e.target.files[0];
    setArchiveImage(file);
    handleSaveImageToAArchive(file);

  }

  return (
    <Container>
      <ToastContainer />
      <Header title="Add Blog" />
     
      <Box m="20px">
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Title"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                sx={{ mb: 2 }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Slug"
                value={slug}
               onChange={(e) => setSlug(e.target.value)}
                sx={{ mb: 2 }}
              />

            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Summary"
                value={summary}
                onChange={(e) => setSummary(e.target.value)}
                sx={{ mb: 2 }}
                multiline
               rows={8}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Description"
                value={mainDescription}
                onChange={(e) => setMainDescription(e.target.value)}
                sx={{ mb: 2 }}
               multiline
               rows={8}
              />
            </Grid>
            
            <Grid item xs={12}>
              <Box my={2}>
                <Typography variant="h6" component="div">
                  Description
                </Typography>
                <Editor
      apiKey="kr3a1pnf8k70cf8449ke7y4i0p8xd4qoyo4ufg9857008jpr"
      initialValue={mode === 'edit' ? blogData.description : ''}
      init={{
        height: 500,
        menubar: true,
        plugins: [
          'advlist autolink lists link image charmap print preview anchor',
          'searchreplace visualblocks code fullscreen',
          'insertdatetime media table paste code help wordcount',
        ],
        toolbar:
          'undo redo | formatselect | ' +
          'bold italic underline strikethrough | ' +
          'alignleft aligncenter alignright alignjustify | ' +
          'outdent indent | ' +
          'file edit view insert | ' +
          'fontsizeselect | fontselect | ' +
          'cut copy paste | ' +
          'table | ' +
          'forecolor backcolor | ' +
          'link unlink | ' +
          'image media | ' +
          'fullscreen | help',
      }}
      onEditorChange={handleEditorChange}
    />
              </Box>
            </Grid>
           

            <Grid item xs={12}>

            <TextField
              fullWidth
              select
                variant="filled"
                label="Is Featured"
              value={isFeatured}
              onChange={(e) => setIsFeatured(e.target.value)}
              sx={{ mb: 2 }}
            >
              <MenuItem value="Yes">Yes</MenuItem>
              <MenuItem value="No">No</MenuItem>
            </TextField>
            </Grid>

         
              <Grid item xs={12}>
             


              <TextField
                fullWidth
                variant="filled"
                
                label="Image Title"
                value={imageTitle}
                onChange={(e) => setImageTitle(e.target.value)}
                sx={{ mb: 2 }}
                />
               
              <TextField
                fullWidth
                variant="filled"
                
                label="Alt Text"
                value={altText}
                onChange={(e) => setAltText(e.target.value)}
                sx={{ mb: 2 }}
                />

               
                </Grid>
            

          
                <Grid item xs={12} display={"flex"}>
           
           <span
             className="uploadImg"
             onClick={() => handleOpenDialog("image")}
           >
           {
              imagePreview ? (
                <div className="image-container">
      <img src={imagePreview} alt="Blog" />
      <div className="overlay">Edit</div>
    </div>
              ) : (
                <>

                <AddPhotoAlternateOutlinedIcon sx={{ fontSize: "6rem" }} />
                <p>Upload Image</p>
                </>
              )
           }
             
           </span>
           </Grid>
            


           {
              (
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    variant="filled"
                    select
                    label="Author"
                    value={author}
                    onChange={(e) => setAuthor(e.target.value)}
                    sx={{ mb: 2 }}
                   
                  >
                    {authors.map((auth) => (
                      <MenuItem key={auth.id} value={auth.name}>
                        {auth.name}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
               
            
              
              )

            }
           
            
            <Grid item xs={10}>
              <TextField
                fullWidth
                variant="filled"
                select
                label="Category"
                value={category}
                onChange={(e) => setCategory(e.target.value)}
                sx={{ mb: 2 }}
              >
                {categories.map((cat) => (
                  <MenuItem key={cat.id} value={cat.name}>
                    {cat.name}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={2}>
              <Button
                onClick={() => setCategoryDialogOpen(true)}
                color="secondary"
                variant="outlined"
                sx={{ mt: 1.5 }}
                fullWidth
              >
                Add Category
              </Button>
            </Grid>
            <Grid item xs={10}>
              <TextField
                fullWidth
                variant="filled"
                select
                label="Tags"
                value={selectedTags}
                onChange={(e) => setSelectedTags(e.target.value)}
                SelectProps={{
                  multiple: true,
                  renderValue: (selected) => (
                    <Box sx={{ display: "flex", flexWrap: "wrap" }}>
                      {selected.map((value) => (
                        <Chip key={value} label={value} sx={{ m: 0.5 }} />
                      ))}
                    </Box>
                  ),
                }}
                sx={{ mb: 2 }}
              >
                {tags.map((tag) => (
                  <MenuItem key={tag.id} value={tag.name}>
                    {tag.name}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={2}>
              <Button
                onClick={() => setTagDialogOpen(true)}
                color="secondary"
                variant="outlined"
                sx={{ mt: 1.5 }}
                fullWidth
              >
                Add Tag
              </Button>
            </Grid>
            <div
              style={{
                border: "1px solid white",
                width: "70vw",
                padding: "10px",
                marginLeft: "25px",
                marginTop: "60px",
                marginBottom: "60px",
              }}
            >
              <Grid item xs={12}>
                <Typography
                  style={{
                    textAlign: "center",
                    marginBottom: "10px",
                    fontSize: "20px",
                  }}
                  variant="h6"
                  component="div"
                >
                  SEO Data
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  variant="filled"
                  type="text"
                  label="SEO Title"
                  value={seoTitle}
                  onChange={(e) => setSeoTitle(e.target.value)}
                  sx={{ mb: 2 }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  variant="filled"
                  type="text"
                  label="SEO Description"
                  value={seoDescription}
                  onChange={(e) => setSeoDescription(e.target.value)}
                  sx={{ mb: 2 }}
                />
              </Grid>
             
              <Grid item xs={12} display={"flex"}>
              <span
                className="uploadImg"
                onClick={() => handleOpenDialog("seoImage")}
              >
              {
                seoImagePreview ? (
                  <div className="image-container">
        <img src={seoImagePreview} alt="Blog" />
        <div className="overlay">Edit</div>
      </div>
                ) : (
                  <>
       
                <AddPhotoAlternateOutlinedIcon sx={{ fontSize: "6rem" }} />
                <p>Upload SEO Image</p>
                </>
                )


              }
             
              </span>
           
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  variant="filled"
                  type="text"
                  label="SEO Keywords"
                  value={keywordInput}
                  onChange={(e) => setKeywordInput(e.target.value)}
                  onKeyPress={handleKeywordInputKeyPress}
                  sx={{ mb: 2 }}
                />
                <Box sx={{ display: "flex", flexWrap: "wrap" }}>
                  {seoKeywords.map((keyword, index) => (
                    <Chip
                      key={index}
                      label={keyword}
                      onDelete={() => handleDeleteKeyword(keyword)}
                      sx={{ m: 0.5 }}
                    />
                  ))}
                </Box>
              </Grid>
            </div>

            <Grid item xs={12} style={{ marginBottom: "30px" }}>
              <Button
                type="submit"
                color="secondary"
                variant="contained"
                fullWidth
                disabled={loading}
              >
             {mode === "edit" 
  ? (loading ? "Editing Blog" : "Edit Blog") 
  : (loading ? "Adding Blog" : "Add Blog")}

              </Button>
            </Grid>
          </Grid>
        </form>
      </Box>
      <Dialog
        open={categoryDialogOpen}
        onClose={() => setCategoryDialogOpen(false)}
      >
        <DialogTitle>Add New Category</DialogTitle>
        <DialogContent>
          <TextField
            fullWidth
            variant="filled"
            label="New Category"
            value={newCategory}
            onChange={(e) => setNewCategory(e.target.value)}
            sx={{ mt: 2 }}
          />
          <TextField
            fullWidth
            variant="filled"
            type="text"
            label="Slug"
            value={categorySlug}
            onChange={(e) => setCategorySlug(e.target.value)}
            sx={{ mt: 2 }}
          />
          <TextField
            fullWidth
            variant="filled"
            type="text"
            label="Description"
            value={categoryDescription}
            onChange={(e) => setCategoryDescription(e.target.value)}
            sx={{ mt: 2 }}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setCategoryDialogOpen(false)}
            color="secondary"
          >
            Cancel
          </Button>
          <Button onClick={handleAddCategory} color="secondary">
            Add
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={authorDialogOpen}
        onClose={() => setAuthorDialogOpen(false)}
      >
        <DialogTitle>Add New Author</DialogTitle>
        <DialogContent>
          <TextField
            fullWidth
            variant="filled"
            label="New Author"
            value={newAuthor}
            onChange={(e) => setNewAuthor(e.target.value)}
            sx={{ mt: 2 }}
          />
          <TextField
            fullWidth
            variant="filled"
            type="text"
            label="Slug"
            value={authorSlug}
           onChange={(e) => setAuthorSlug(e.target.value)}
            sx={{ mt: 2 }}
          />
          <TextField
            fullWidth
            variant="filled"
            type="text"
            label="Description"
            value={authorDescription}
            onChange={(e) => setAuthorDescription(e.target.value)}
            sx={{ mt: 2 }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setAuthorDialogOpen(false)} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleAddAuthor} color="secondary">
            Add
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
          open={openDialog}
          onClose={handleCloseDialog}
          sx={{
            height: "100%",
            width: "100%",
            overflow: "auto",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            // marginLeft: "10rem",
            // marginTop: "5rem",
            // position: 'absolute',
            // top: '50%',
            // left: '50%',
            // transform: 'translate(-50%, -50%)',
          }}
          PaperProps={{
            style: {
              margin: 0,
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            },
          }}
        >
        
            <Box display="flex" justifyContent="center" alignItems="center" sx={{ p: 2}}>
            <Button
            sx={{
              display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#141b2d",
    color: "white",
    margin: "1rem",
    "&:hover": {
      backgroundColor: "#141b2d",
      borderColor: "#141b2d",
      color: "white",
    },
            }}
              variant={archiveOrUpload === "upload" ? "contained" : "outlined"}
              onClick={() => setArchiveOrUpload("upload")}
              sx={
                archiveOrUpload === "upload"
                  ? {
                      backgroundColor: "#141b2d",

                      color: "white",
                      
                  }
                  : {
                      backgroundColor: "transparent",
                      borderColor: "#141b2d",
                      color: "#4cceac",
                    }
              }
            >        Upload
      </Button>
      <Button
      sx={{
        display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#141b2d",
    color: "white",
    margin: "1rem",
    "&:hover": {
      backgroundColor: "#141b2d",
      borderColor: "#141b2d",
      color: "white",
    },
      }}
              variant={archiveOrUpload === "upload" ? "outlined" : "contained"}
              onClick={() => setArchiveOrUpload("archive")}
              sx={
                archiveOrUpload === "archive"
                  ? {
                      backgroundColor: "#141b2d",
                      borderColor: "#141b2d",
                      color: "white",
                  }
                  : {
                      backgroundColor: "transparent",
                      borderColor: "#141b2d",
                      color: "#4cceac",
                    }
              }
            >
              Archive
            </Button>
    </Box>

    {archiveOrUpload === 'upload' ? (
      <label htmlFor="file-upload"> 
      <Box
                p={1}
                display="flex"
                justifyContent={"center"}
                alignItems={"center"}
                sx={{
                  cursor: "pointer",
                  width: 500,
                  height: 300,
                  backgroundColor: "#141b2d",
                  margin: "2rem",
                  border: "1px solid #141b2d",
                  "@media (max-width:800px)": {
                    width: 250,
                    height: 200,
                  },
                }}
              >  
    <CloudUploadIcon />
    <Typography ml={1} mt={1} variant="h5" gutterBottom style={{cursor:"pointer"}}>
      Upload
    </Typography>
 
  <input 
    id="file-upload"
    hidden 
    accept="image/*" 
    type="file" 
    onChange={(e) => {

      if(type==='image'){
        setImage(e.target.files[0]);
        setImagePreview(URL.createObjectURL(e.target.files[0]));
      }else{
        setSeoImage(e.target.files[0]);
        setSeoImagePreview(URL.createObjectURL(e.target.files[0]));
      }
    setOpenDialog(false);}
    } 
  />
</Box>
 </label>
      ): (   <Box
              p={2}
              display={"flex"}
              justifyContent={"center"}
              alignItems={"center"}
              flexWrap={"wrap"}
              sx={{
                cursor: "pointer",
                width: 580,
                height: 500,
                "@media (max-width:800px)": {
                  width: 300,
                },
              }}
            >
      <label htmlFor="file-archive-upload">

      <Paper sx={{ p: 2, m: 1,cursor:"pointer" }}  >
      <img
                    src={addIcon}
                    alt="Archive"
                    style={{
                      width: 80,
                      height: 80,
                      objectFit: "contain",
                    }}
                  />
   <input 
    id="file-archive-upload"
    hidden 
    accept="image/*" 
    type="file" 
    onChange={handleImageArchiveChange} 
  />
</Paper>
      </label> 
          {archive.map((item, index) => (
                       <Paper key={index} sx={{ p: 2, m: 1,cursor:"pointer" }} onClick={()=>{handleSetArchiveImage(item.Image)}}>

                       <img
                    src={item.Image}
                    alt="Archive"
                    style={{
                      width: 80,
                      height: 80,
                      objectFit: "contain",
                    }}
                  />
            </Paper>
          ))}
        </Box>)}
    
       
      </Dialog>
      
      <Dialog open={tagDialogOpen} onClose={() => setTagDialogOpen(false)}>
        <DialogTitle>Add New Tag</DialogTitle>
        <DialogContent>
          <TextField
            fullWidth
            variant="filled"
            label="New Tag"
            value={newTag}
            onChange={(e) => setNewTag(e.target.value)}
            sx={{ mt: 2 }}
          />
          <TextField
            fullWidth
            variant="filled"
            type="text"
            label="Slug"
            value={tagSlug}
           onChange={(e) => setTagSlug(e.target.value)}
            sx={{ mt: 2 }}
          />
          <TextField
            fullWidth
            variant="filled"
            type="text"
            label="Description"
            value={tagDescription}
            onChange={(e) => setTagDescription(e.target.value)}
            sx={{ mt: 2 }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setTagDialogOpen(false)} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleAddTag} color="secondary">
            Add
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default BlogAddPage;
