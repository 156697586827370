import React, { useState, useEffect } from 'react';
import { Box, Typography, useTheme, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { collection, getDocs, deleteDoc, doc, updateDoc } from 'firebase/firestore';
import { Link, useNavigate } from 'react-router-dom';
import { db } from '../../firebase/firebase';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { tokens } from '../../theme';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Header from '../../components/Header';

const AllBlogsPage = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [blogs, setBlogs] = useState([]);
  const [open, setOpen] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [selectedBlog, setSelectedBlog] = useState(null);
  const [selectedBlogToDelete, setSelectedBlogToDelete] = useState(null);
  const [editData, setEditData] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    document.title = 'All Blogs | Admin Panel';
    console.log('Fetching blogs... useEffect all blogs page');
    const fetchBlogs = async () => {
      console.log('Fetching blogs... try block all blogs page');
      try {
        const blogsRef = collection(db, 'blogs');
        const querySnapshot = await getDocs(blogsRef);
        const blogsData = querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
        setBlogs(blogsData);
      } catch (error) {
        console.error('Error fetching blogs:', error);
      }
    };
    fetchBlogs();
  }, []);

  const handleDeleteDialogOpen = (blogId) => {
    setSelectedBlogToDelete(blogId);
    setOpenDeleteDialog(true);
  };

  const handleDeleteDialogClose = () => {
    setOpenDeleteDialog(false);
    setSelectedBlogToDelete(null);
  };

  const handleConfirmDelete = async () => {
    try {
      console.log('Deleting blog...');
      await deleteDoc(doc(db, 'blogs', selectedBlogToDelete));
      setBlogs((prevBlogs) => prevBlogs.filter((blog) => blog.id !== selectedBlogToDelete));
      toast.success('Blog deleted successfully!');
      handleDeleteDialogClose();
    } catch (error) {
      console.error('Error deleting blog:', error);
      toast.error('Error deleting blog. Please try again.');
    }
  };

  const handleEdit = (blog) => {
    setSelectedBlog(blog);
    setEditData(blog);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedBlog(null);
    setEditData({});
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEditData((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleSubmit = async () => {
    if (selectedBlog) {
      try {
        console.log('Updating blog...');
        const blogRef = doc(db, 'blogs', selectedBlog.id);
        await updateDoc(blogRef, editData);
        setBlogs(blogs.map((blog) => (blog.id === selectedBlog.id ? { ...blog, ...editData } : blog)));
        toast.success('Blog updated successfully!');
        handleClose();
      } catch (error) {
        console.error('Error updating blog:', error);
        toast.error('Error updating blog. Please try again.');
      }
    }
  };

  const columns = [
    { field: 'title', headerName: 'Title', flex: 1 },
    { field: 'summary', headerName: 'Summary', flex: 1 },
    { field: 'category', headerName: 'Category', flex: 1 },
    {
      field: 'action',
      headerName: 'Action',
      flex: 1,
      renderCell: (params) => (
        <Box>
          <Button component={Link} to={`/blog/${params.row.id}`} color="primary" variant="contained">
            View
          </Button>
          <Button onClick={() => navigate('/addblog', { state: { mode: 'edit', blogData: params.row } })} color="info" variant="contained" startIcon={<EditIcon />} sx={{ ml: 1 }}>
            Edit
          </Button>
          <Button onClick={() => handleDeleteDialogOpen(params.row.id)} color="error" variant="contained" startIcon={<DeleteIcon />} sx={{ ml: 1 }}>
            Delete
          </Button>
        </Box>
      ),
    },
  ];

  return (
    <Box m="20px">
      <ToastContainer />
      <Header title="ALL BLOGS" subtitle="Managing All BLOGS" />
      <Box style={{ display: 'flex', justifyContent: 'flex-end', marginRight: '20px' }}>
        <Button variant="contained" color="secondary" onClick={() => navigate('/addBlog')}>
          Add Blog
        </Button>
      </Box>
      <Box
        m="20px 0"
        height="75vh"
        sx={{
          '& .MuiDataGrid-root': {
            border: 'none',
          },
          '& .MuiDataGrid-cell': {
            borderBottom: 'none',
          },
          '& .MuiDataGrid-columnHeaders': {
            backgroundColor: colors.blueAccent[700],
            borderBottom: 'none',
          },
          '& .MuiDataGrid-virtualScroller': {
            backgroundColor: colors.primary[400],
          },
          '& .MuiDataGrid-footerContainer': {
            borderTop: 'none',
            backgroundColor: colors.blueAccent[700],
          },
          '& .MuiCheckbox-root': {
            color: `${colors.greenAccent[200]} !important`,
          },
        }}
      >
        <DataGrid rows={blogs} columns={columns} getRowId={(row) => row.id} />
      </Box>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Edit Blog Post</DialogTitle>
        <DialogContent>
          <DialogContentText>To edit the blog post, please modify the details below.</DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            name="title"
            label="Title"
            type="text"
            fullWidth
            variant="standard"
            value={editData.title || ''}
            onChange={handleChange}
          />
          <TextField
            margin="dense"
            name="summary"
            label="Summary"
            type="text"
            fullWidth
            variant="standard"
            value={editData.summary || ''}
            onChange={handleChange}
          />
          <TextField
            margin="dense"
            name="category"
            label="Category"
            type="text"
            fullWidth
            variant="standard"
            value={editData.category || ''}
            onChange={handleChange}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            Close
          </Button>
          <Button onClick={handleSubmit} color="primary">
            Submit
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openDeleteDialog} onClose={handleDeleteDialogClose}>
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <DialogContentText>Are you sure you want to delete this blog?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteDialogClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleConfirmDelete} color="error">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default AllBlogsPage;
